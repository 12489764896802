import Vue, {VNode, VueConstructor} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./registerServiceWorker";
import {Toastr} from "./plugins/Toastr/Toastr";
import {Authenticator} from "./plugins/Authenticator";
import {Http} from "@/plugins/Http";
import {authenticator} from "@/util/authenticator";
import VueScrollTo from "vue-scrollto";
import TDSDate from "@/util/TDSDate";
import {Regexes} from "@/plugins/Regexes";
import VTooltip from "v-tooltip";

require("dotenv").config();

Vue.config.productionTip = false;

Vue.use(Toastr);
Vue.use(Authenticator);
Vue.use(Http);
Vue.use(VueScrollTo);
Vue.use(Regexes);
Vue.use(VTooltip, {defaultPlacement: "right"});

// TODO: Get currency info from API and make format based on that...

Vue.filter("camelCaseToSeparated", (value: string) => {
    return value.replace(/[A-Z]/g, (match: string) => " " + match.toLowerCase());
});

Vue.filter("cryptoFormat", (value: number | string, maximumFractionDigits: number, code: string) => {
    if (typeof value === "string") value = parseFloat(value);
    return new Intl
        .NumberFormat("en", {maximumFractionDigits})
        .format(value) + " " + code;
});

Vue.filter("fiatFormat", (value: number | string, currencySign: boolean = false) => {
    if (typeof value === "string") value = parseFloat(value);
    return new Intl.NumberFormat("en", {
        style: "currency",
        currency: "USD",
        currencyDisplay: currencySign ? "symbol" : "code"
    }).format(value);
});

Vue.filter("percentageFormat", (value: number | string) => {
    if (typeof value === "string") value = Number(value);
    return (value > 0 ? "+" : "") + value.toFixed(2) + "%";
});

Vue.filter("timeFormat", (value: number | string, format: string) => {
    if (typeof value !== "number") return value;
    switch (format) {
        case "d": // @ts-ignore
            return Math.round(value / 60 / 24) + " " + i18n.tc("general.timeAppendices.d", Math.round(value / 60 / 24));
        default:
            return value;
    }
});

Vue.filter("dateFormat", (value: number | string, format: string) => {
    if (typeof value !== "string") return value;
    // @ts-ignore
    return new TDSDate(value).format(format || "DD-MM-YYYY HH:mm");
});

Vue.filter("byteFormat", (value: number) => {
    return getByteFormat(value, "Byte");
});

function getByteFormat(val: number, unit: string): string {

    const result: string = (val/1073741824).toFixed(2);
    return result + " GB";


    /*if(val > 1024) {
        switch (unit) {
            case "Byte":
                return getByteFormat(val / 1024, "KB");
            case "KB":
                return getByteFormat(val / 1024, "MB");
            case "MB":
                return getByteFormat(val / 1024, "GB");
            default:
                return val + unit;
        }
    }else {
        return val + unit;
    }*/
}

async function init() {
    try {
        const accessToken: string | undefined = await authenticator.getAccessToken();
        console.log("[main] Got access token: ", accessToken);
        store.commit("SET_AUTH", accessToken ?? "");
        // if (accessToken) {
        //     await store.dispatch("FETCH_ACCOUNT");
        // } else {
        //     window.localStorage.clear();
        // }
        if(!accessToken) window.localStorage.clear();
    } catch (err) {
        window.localStorage.clear();
        store.commit("SET_AUTH", "");
        await authenticator.signOut();
        console.error("[App] Unexpected error on getting access token: ", err);
    }

    new Vue({
        router,
        i18n,
        store,
        render: (h: (component: VueConstructor) => VNode) => h(App)
    }).$mount("#app");
}

init().then(() => console.log("[main] Application started."));
