















import {Component, Prop, Vue} from "vue-property-decorator";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

@Component({
    components: {
        TDSSpinner
    }
})
export default class TDSButton extends Vue {
    @Prop({
        required: true,
        validator: function (value: string) {
            return ["primary", "secondary", "tertiary", "single-sign", "round-red", "primary-red", "secondary-red", "link", "export-link", "icon-square"].indexOf(value) !== -1;
        }
    }) buttonStyle!: string;
    @Prop({type: Boolean, default: false}) inactive!: boolean;
    @Prop({type: String}) width!: string;
    @Prop({type: String, default: "submit"}) type!: string;
    @Prop({type: Boolean, default: false}) showSpinner!: boolean;

    private click(e: Event) {
        if (this.inactive) return;
        this.$emit("click", e);
    }
}
