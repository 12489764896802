










import {VueClassTemplate} from "@/util/VueClassTemplate";
import {Component, Prop} from "vue-property-decorator";
@Component({})
export default class BubbleTabs extends VueClassTemplate{
    @Prop({type: Array, required: true}) tabs!: Array<{name: string; link: string}>;
}
