
















































































import {Component, Vue, Watch} from "vue-property-decorator";
import TDSDate, {TDSDateUnit} from "@/util/TDSDate";

interface WeekDayLegend {
    shortName: string;
    index: number;
}

interface Day {
    date: TDSDate;
    day: number;
    id: string;
    isOutOfMonth: boolean;
}

export interface TimeRange {
    start: TDSDate;
    end: TDSDate;
}

@Component
export default class TDSDatePicker extends Vue {
    private time: TDSDate = new TDSDate();
    private monthYear: string = this.time.format("MMM YYYY");
    private daysPerWeek: Array<Day[]> = [];
    private selectedStartDay: Day | null = null;
    private selectedEndDay: Day | null = null;
    private startTimeValid: boolean = true;
    private endTimeValid: boolean = true;
    private endTime: string = "00:00";
    private startTime: string = "00:00";

    // // // // // // // // // // HOOKS // // // // // // // // // //

    public created() {
        this.daysPerWeek = this.calculateDaysPerWeek();
    }

    // // // // // // // // // // WATCH // // // // // // // // // //

    @Watch("time")
    public onTimeChange() {
        this.monthYear = this.time.format("MMM YYYY");
        this.daysPerWeek = this.calculateDaysPerWeek();
    }

    @Watch("selectedStartDay")
    public selectedStartDayChange() {
        this.$emit("select", {
            start: this.selectedStartDay?.date,
            end: this.selectedEndDay?.date
        });
    }

    @Watch("selectedEndDay")
    public selectedEndDayChange() {
        this.$emit("select", {
            start: this.selectedStartDay?.date,
            end: this.selectedEndDay?.date
        });
    }

    // // // // // // // // // // GETTER // // // // // // // // // //

    private get daysLegend(): WeekDayLegend[] {
        return [...Array(7)].map((_: null, index: number): WeekDayLegend => {
            return {
                index,
                shortName: this.$t("general.weekDayShort." + index) + ""
            };
        });
    }

    // // // // // // // // // // METHODS // // // // // // // // // //

    private onStartTimeChange(event: InputEvent) {
        this.startTimeValid = true;
        const startTime: string = (event.target as HTMLInputElement).value;
        if (/[0-9]{2}:[0-9]{2}/.test(startTime)) { // HH:mm
            const date = this.selectedStartDay?.date;
            date?.setHours(Number(startTime.split(":")[0]));
            date?.setMinutes(Number(startTime.split(":")[1]));
            this.$emit("select", {
                start: this.selectedStartDay?.date,
                end: this.selectedEndDay?.date
            });
        } else {
            this.startTimeValid = false;
        }
    }

    private onEndTimeChange(event: InputEvent) {
        this.endTimeValid = true;
        const endTime: string = (event.target as HTMLInputElement).value;
        if (/[0-9]{2}:[0-9]{2}/.test(endTime)) { // HH:mm
            const date = this.selectedEndDay?.date;
            date?.setHours(Number(endTime.split(":")[0]));
            date?.setMinutes(Number(endTime.split(":")[1]));
            this.$emit("select", {
                start: this.selectedStartDay?.date,
                end: this.selectedEndDay?.date
            });
        } else {
            this.endTimeValid = false;
        }
    }

    private dayIsHighlighted(day: Day) {
        if (day.id === this.selectedStartDay?.id || day.id === this.selectedEndDay?.id) {
            return true;
        }
        return !!(this.selectedEndDay && this.selectedStartDay && day.date.getTime() < this.selectedEndDay.date.getTime() && day.date.getTime() > this.selectedStartDay.date.getTime());
    }

    private select(day: Day) {
        if (!this.selectedStartDay) {
            this.selectedStartDay = day;
            return;
        }
        if (!this.selectedEndDay) {
            if (day.date.getTime() > this.selectedStartDay.date.getTime())
                this.selectedEndDay = day;
            if (day.date.getTime() <= this.selectedStartDay.date.getTime()) {
                this.selectedEndDay = this.selectedStartDay;
                this.selectedStartDay = day;
            }
            return;
        }
        this.selectedStartDay = day;
        this.selectedEndDay = null;
    }

    private calculateDaysPerWeek(): Array<Day[]> {
        let loopDate = new TDSDate(this.time.getTime());
        loopDate.setHours(0);
        loopDate.setMinutes(0);
        loopDate.setDate(1);
        const dayOffset = loopDate.getDay();
        loopDate = loopDate.add(-dayOffset, TDSDateUnit.DAY);
        const days: Array<Day[]> = [];
        while (true) {
            let weekIndex = Math.floor((loopDate.getDate() + dayOffset - 1) / 7);
            let isOutOfMonth = false;
            if ((loopDate.getMonth() < this.time.getMonth() && loopDate.getFullYear() === this.time.getFullYear()) || loopDate.getFullYear() < this.time.getFullYear()) {
                weekIndex = 0;
                isOutOfMonth = true;
            } else if ((loopDate.getMonth() > this.time.getMonth() && loopDate.getFullYear() === this.time.getFullYear()) || loopDate.getFullYear() > this.time.getFullYear()) {
                if (days[days.length - 1].length === 7) return days;
                weekIndex = days.length - 1;
                isOutOfMonth = true;
            }
            if (!days[weekIndex]) days[weekIndex] = [];
            days[weekIndex].push({
                isOutOfMonth,
                date: loopDate,
                day: loopDate.getDate(),
                id: loopDate.format("YYYY-MM-DD")
            });
            loopDate = loopDate.add(1, TDSDateUnit.DAY);
        }
    }

    private add(amount: number, type: string) {
        switch (type) {
            case "YEAR":
                this.time = this.time.add(amount, TDSDateUnit.YEAR);
                break;
            case "MONTH":
                this.time = this.time.add(amount, TDSDateUnit.MONTH);
                break;
        }
    }
}
