














































import {VueClassTemplate} from "@/util/VueClassTemplate";
import DefaultCard from "@/components/partials/DefaultCard.vue";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import Component from "vue-class-component";
import {MarketplaceOffer} from "@/interfaces/entities/MarketplaceOffer";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import TDSButton from "@/components/common/TDSButton.vue";

@Component({
    components: {
        TDSButton,
        TDSModal,
        TableList, DefaultCard}
})

export default class Marketplace extends VueClassTemplate{
    private marketplaceOffers: Array<MarketplaceOffer> = [];
    private showModal: boolean = false;
    private userId: string = this.$route.params.userId;
    private selectedOffer: MarketplaceOffer = {
        marketOfferId: 0,
        "display_size": 0,
        "display_unit": "",
        totalPrice: 0,
        priceRounding: "",
        totalPriceRounding: "",
        createdAt: "",
        expiresAt: ""
    }

    async init(){
        this.startProgress("loadMarketplace");
        await this.loadMarketplace();
        this.endProgress("loadMarketplace");
    }

    async loadMarketplace(){
        try {
            this.marketplaceOffers = await this.$store.dispatch("GET_MARKET_OFFERINGS", this.userId);
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
    }
    async deleteListing(){
        try {
            await this.$store.dispatch("DELETE_MARKET_OFFER", {userId: this.userId, offerId: this.selectedOffer.marketOfferId});
            this.$toastr.success("Offer successfully deleted!", 5000, true);
        }
        catch (e) {
            this.$toastr.error("Error while deleting market offer.", 5000, true);
            this.error = "<b>Unable to delete market offer:</b><br>" + e.message;
        }

        await this.loadMarketplace();
        this.showModal = false;

    }
    calcValidity(date: string){
        const expires = new Date(date);
        const now = new Date();
        const diff = Math.abs(expires.getTime() - now.getTime());
        const days = Math.floor( diff / (1000 * 3600 * 24));
        return days;
    }
    calcListed(date: string){
        const createdAt = new Date(date);
        const now = new Date();
        const diff = Math.abs(now.getTime() - createdAt.getTime());
        const days = Math.floor( diff / (1000 * 3600 * 24));
        return days;
    }

    get MarketplaceColumns(): TableListColumnConfig{
        return{
            data: {label: "Data", sortable: true},
            validity: {label: "validity", sortable: true},
            price: {label: "price", sortable: true},
            listedOnMarket:{label: "listed on market", sortable: true},
            delete: {label: "", sortable: false}
        };
    }

    openModal(data: MarketplaceOffer){
        this.selectedOffer = data;
        this.showModal = true;
    }
}



