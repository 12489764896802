






import {Component, Vue} from "vue-property-decorator";

@Component
export default class App extends Vue {

    mounted() {
        this.$store.commit("SET_IS_MOBILE", (document.documentElement.clientWidth <= 920));
        window.addEventListener("resize", this.handleResize);
    }

    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    }

    public handleResize() {
        this.$store.commit("SET_IS_MOBILE", (document.documentElement.clientWidth <= 920));
    }
}
