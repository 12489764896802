


















































import {Component, Vue, Watch} from "vue-property-decorator";
import { Route } from "vue-router";
import DefaultFooter from "@/components/partials/DefaultFooter.vue";
import MobileMenuButton
    from "@/components/partials/Navigation/MobileMenuButton.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

@Component({
    components: {TDSSpinner, MobileMenuButton, DefaultFooter}
})
export default class DefaultNavigationBar extends Vue {
    private mobileNavOpen: boolean = false;
    private isLoading: boolean = false;

    get authenticated(): boolean {
        return !!this.$store.state.auth;
    }

    get userBalance(): number {
        return this.$store.state.user?.balance;
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get previousView(): boolean {
        return this.$store.state.previousView;
    }

    @Watch("$route", {immediate:true, deep:true})
        onUrlChange(newVal: Route){
        this.mobileNavOpen = false;
    }

    @Watch("mobileNavOpen")
    mobileNavChanged(newVal: boolean) {
        if (newVal) document.body.style.position = "fixed";
        else document.body.style.position = "relative";
    }

    @Watch("isMobile")
    mobileChanged(newVal: boolean) {
        if (!newVal) this.mobileNavOpen = false;
    }

    @Watch("authenticated")
    authChanged(newVal: boolean) {
        if (!newVal) this.mobileNavOpen = false;
    }

    public closeMenu(){
        this.mobileNavOpen = !this.mobileNavOpen;
    }

    public async login(): Promise<void> {
        this.isLoading = true;
        try {
            await this.$authenticator.signIn();
        } catch (e) {
            this.$toastr.error(this.$t("navigation.errors.login"), e);
            this.isLoading = false;
        }
    }

    public async logout(): Promise<void> {
        this.isLoading = true;
        try {
            await this.$authenticator.signOut();
        } catch (e) {
            this.$toastr.error(this.$t("navigation.errors.logout"), e);
            this.isLoading = false;
        }
    }
}
