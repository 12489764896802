var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultCard',{attrs:{"title":"Voice Plans","teaser":'A list of the users Voice-Plans',"is-busy":_vm.isBusy,"error":_vm.error}},[_c('TableList',{staticClass:"mb-6",attrs:{"columns":_vm.voicePlanColumns,"rows":_vm.voicePlans,"empty-text":"The user has no active Voice-Plans"},scopedSlots:_vm._u([{key:"column_country",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.country || "---")+" ")]}},{key:"column_balance",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.currentSizeValue)+" "+_vm._s(data.sizeUnit)+" ")]}},{key:"column_expiration",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.calcExpiry(data.expiresAt) + " Days " || "---")+" ")]}}])})],1),_c('DefaultCard',{attrs:{"title":"Call History","teaser":'A list of the users call-history',"is-busy":_vm.isBusy,"error":_vm.error}},[_c('TableList',{staticClass:"mb-6",attrs:{"columns":_vm.callHistoryColumns,"rows":_vm.callhistory,"empty-text":"The user has no registered calls"},scopedSlots:_vm._u([{key:"column_number",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.calledNumber || "---")+" ")]}},{key:"column_initiation",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.callStartedAt ? _vm.$options.filters.dateFormat(data.callStartedAt) : "---")+" ")]}},{key:"column_duration",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.callDurationInSeconds || "---")+" ")]}},{key:"column_plan",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.voicePlansUsed.length > 0 ? data.voicePlansUsed[0].country : "---")+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }