







import {Component, Vue} from "vue-property-decorator";

@Component
export default class OutsideClick extends Vue {
    private componentId: string = "oc" + Math.random().toString(36).substr(2, 6);
    private mouseDownTimestamp: number = 0;

    mounted () {

        //  Necessary to unbind from opening click!
        setTimeout(() => {
            document.addEventListener("mousedown", this.onMouseDown.bind(this));
            document.addEventListener("click", this.onOutsideClick.bind(this));
        }, 100);
    }
    beforeDestroy () {
        document.addEventListener("mousedown", this.onMouseDown.bind(this));
        document.removeEventListener("click", this.onOutsideClick.bind(this));
    }

    public onOutsideClick (event: MouseEvent): void {
        // @ts-ignore
        if (Date.now() - this.mouseDownTimestamp < 300 && !event.target.closest("#" + this.componentId)) {
            document.removeEventListener("click", this.onOutsideClick.bind(this));
            this.$emit("outsideClicked", true);
        }
    }

    public onMouseDown (event: Event): void {
        this.mouseDownTimestamp = Date.now();
    }
}
