


















































import {VueClassTemplate} from "@/util/VueClassTemplate";
import {Component, Watch} from "vue-property-decorator";
import DefaultCard from "@/components/partials/DefaultCard.vue";
import BubbleTabs from "@/components/partials/Navigation/BubbleTabs.vue";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import {VoicePlanEntry} from "@/interfaces/entities/VoicePlanEntry";
import {CallEntry} from "@/interfaces/entities/CallEntry";

@Component({
    components: {
        TableList,
        DefaultCard,
        BubbleTabs
    }
})
export default class VoicePlansDetails extends VueClassTemplate{
    private userId: string = this.$route.params.userId;
    private voicePlans: Array<VoicePlanEntry> = [];
    private callhistory: Array<CallEntry> = [];


    @Watch("error")
    errorChanged() {
        console.log("error changed: ", this.error);
    }
    async init() {
        this.startProgress("loading");
        try {
            await this.loadVoicePlans();
            await this.loadCallHistory();
        }
        finally {
            this.endProgress("loading");
        }


    }

    get voicePlanColumns(): TableListColumnConfig{
        return {
            country: {label: "Country", sortable: true},
            balance: {label: "Voice Balance", sortable: true, sortBy: (item: VoicePlanEntry) => item.currentSizeValue},
            expiration: {label: "Expires in", sortable: true, sortBy: (item: VoicePlanEntry) => this.calcExpiry(item.expiresAt)}
        };
    }

    get callHistoryColumns(): TableListColumnConfig{
        return {
            number: {label: "Number", sortable: true, sortBy: (item: CallEntry) => item.calledNumber},
            plan: {label: "Voice Plan / Country", sortable: true, sortBy: (item: CallEntry) => item.voicePlansUsed[0]?.country || "---"},
            duration: {label: "Call duration", sortable: true, sortBy: (item: CallEntry) => item.callDurationInSeconds},
            initiation: {label: "Call initiation time", sortable: true, sortBy: (item: CallEntry) => item.callStartedAt}
        };
    }

    async loadVoicePlans(){
        try {
            this.voicePlans = await this.$store.dispatch("GET_VOICE_PLANS", this.userId);

        } catch (e) {
            this.error = ("Error while loading voice-plans: " +e.message);
        }
    }

    async loadCallHistory(){
        try {
            this.callhistory = await this.$store.dispatch("GET_CALL_HISTORY", this.userId);
        } catch (e) {
            this.error = ("Error while loading call-history: " +e.message);
        }
    }

    calcExpiry(date: string){
        const expires = new Date(date);
        const now = new Date();
        const diff = Math.abs(expires.getTime() - now.getTime());
        const days = Math.round( diff / (1000 * 3600 * 24));
        return days;
    }

}
