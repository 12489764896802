

































import {Component, Prop} from "vue-property-decorator";
import {VueClassTemplate} from "@/util/VueClassTemplate";
import DefaultCard from "../DefaultCard.vue";
import InputField from "@/components/common/InputField.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import {DentUser} from "@/interfaces/entities/DentUser";
import {GetUserFlagsResponse} from "@/interfaces/httpResponses/GetUserFlagsResponse";
import SelectField from "@/components/common/SelectField.vue";
import TDSError from "@/components/common/TDSError.vue";

@Component({
    components: {DefaultCard, InputField, TDSButton, SelectField, TDSError}
})
export default class FlagUserForm extends VueClassTemplate {

    @Prop() user!: DentUser;

    flags: {flagName: string}[] = [];
    usersFlags: {flagName: string}[] = [];
    selectedFlag: string = "";

    async init() {
        this.startProgress("loading");
        try {
            const userFlags: GetUserFlagsResponse = await this.$store.dispatch("FETCH_USER_FLAGS");
            this.flags = userFlags.flags.map((flagName: string) => ({ flagName }));

            this.usersFlags = (await this.$store.dispatch("FETCH_USERS_FLAGS", this.user.login)).flags;
        } catch(e) {
            this.error = "<b>Error while loading user flags:</b><br>" + e.message;
        }
        this.endProgress("loading");
    }

    onSelectFlag(flagName: string) {
        this.selectedFlag = flagName;
    }

    async flagUser() {
        this.startProgress("flagging");
        try {
            await this.$store.dispatch("ADD_FLAG_TO_USER", {
                flagName: this.selectedFlag,
                username: this.user.login
            });
            await this.init();
        } catch(e) {
            this.error = "<b>Error while adding flag to user:</b><br>" + e.message;
        }
        this.endProgress("flagging");
    }

    async deleteFlagFromUser(flagName: string) {
        this.startProgress("removing_flag");
        try {
            await this.$store.dispatch("DELETE_FLAG_FROM_USER", {
                flagName, username: this.user.login
            });
            await this.init();
        } catch(e) {
            this.error = "<b>Error while deleting flag from user:</b><br>" + e.message;
        }
        this.endProgress("removing_flag");
    }
}
