









import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class TDSCheckbox extends Vue{
    @Prop({type: Boolean, default: false}) activated!: boolean;
}
