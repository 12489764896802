var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultCard',{attrs:{"title":"Flagged Users","teaser":"All available flags and flagged users are shown here.","error":_vm.error}},[_c('div',{staticClass:"flex-row mb-4"},[_c('InputField',{ref:"searchQueryInput",attrs:{"label":"Add a new flag","placeholder":"Enter name like 'fraudy'...","required":true,"value":_vm.newFlagName},on:{"enter-pressed":_vm.addFlag,"update:value":function($event){_vm.newFlagName=$event}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v(" Please enter a valid flag name. ")]},proxy:true}])}),_c('TDSButton',{class:{'mt-5': !_vm.isMobile},attrs:{"button-style":"primary-red","type":"button","width":"10%","inactive":_vm.isBusy,"show-spinner":_vm.isBusy},on:{"click":_vm.addFlag}},[_vm._v(" Add Flag ")])],1),_c('div',{staticClass:"flags mb-6"},[_c('label',[_vm._v("Flags")]),_vm._l((_vm.flags),function(flag){return _c('span',{key:flag,staticClass:"badge mr-2 mb-2 no-select",class:{ selected: _vm.selectedFlags.includes(flag)},on:{"click":function($event){return _vm.selectFlag(flag)}}},[_vm._v(" "+_vm._s(flag)+" ")])})],2),_c('TableList',{staticClass:"mb-4",attrs:{"columns":_vm.userTableColumn,"rows":_vm.selectedFlaggedUsers,"empty-text":'No flagged user for \'' + _vm.selectedFlags.join('\', \'') + '\''},scopedSlots:_vm._u([{key:"column_flagname",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.flagName)+" ")]}},{key:"column_username",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.username)+" ")]}},{key:"column_remove",fn:function(ref){
var data = ref.data;
return [_c('TDSButton',{attrs:{"button-style":"link","inactive":_vm.isBusy},on:{"click":function($event){return _vm.removeFlagFromUser(data)}}},[_vm._v(" remove ")])]}},{key:"column_open",fn:function(ref){
var data = ref.data;
return [_c('TDSButton',{attrs:{"button-style":"link","inactive":_vm.isBusy},on:{"click":function($event){return _vm.openUser(data.username)}}},[_vm._v(" open ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }