
















import {Component, Prop, Vue} from "vue-property-decorator";
import TDSError from "@/components/common/TDSError.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

@Component({
    components: {TDSError, TDSSpinner}
})
export default class DefaultCard extends Vue {
    @Prop({type: Boolean, default: false}) isBusy!: boolean;
    @Prop({type: String}) title!: string;
    @Prop({type: String}) teaser!: string;
    @Prop({type: String}) error!: string;
    @Prop({type: String, default: "0px"}) minHeight!: string;
}
