import i18n from "../i18n";

//  We can use the i18n util outside Vue with the following code.
function translate(key: string) {
    return i18n.tc(key);
}

export enum TDSDateUnit {
    SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR
}

export default class TDSDate extends Date {

    static hourLength: number = 1000 * 60 * 60;
    static dayLength: number = 1000 * 60 * 60 * 24;
    static monthLength: number = 1000 * 60 * 60 * 24 * 30;

    constructor(timestamp?: number) {
        super(timestamp ?? Date.now());
    }

    public format(format: string = "YYYY-MM-DD HH:mm:ss"): string {
        format = format.replace("YYYY", this.getFullYear() + "");
        format = format.replace("MMM", translate("general.monthShort." + this.getMonth()));
        format = format.replace("MM", TDSDate.addLeadingZero(this.getMonth() + 1));
        format = format.replace("DD", TDSDate.addLeadingZero(this.getDate()));
        format = format.replace("HH", TDSDate.addLeadingZero(this.getHours()));
        format = format.replace("mm", TDSDate.addLeadingZero(this.getMinutes()));
        format = format.replace("ss", TDSDate.addLeadingZero(this.getSeconds()));
        return format;
    }

    public add(amount: number, unit: TDSDateUnit): TDSDate {
        const d = new TDSDate(this.getTime());
        if (unit === TDSDateUnit.HOUR) {
            return new TDSDate(d.setHours(d.getHours() + amount));
        } else if (unit === TDSDateUnit.DAY) {
            return new TDSDate(d.setDate(d.getDate() + amount));
        } else if (unit === TDSDateUnit.MONTH) {
            return new TDSDate(d.setMonth(d.getMonth() + amount));
        } else if (unit === TDSDateUnit.YEAR) {
            return new TDSDate(d.setFullYear(d.getFullYear() + amount));
        } else {
            throw new Error("Unknown TDSDateUnit '" + unit + "'");
        }
    }

    static daysInBetween(date1: TDSDate, date2: TDSDate) {
        return TDSDate.between(date1, date2, TDSDateUnit.DAY);
    }

    static hoursInBetween(date1: TDSDate, date2: TDSDate) {
        return TDSDate.between(date1, date2, TDSDateUnit.HOUR);
    }

    static monthsInBetween(date1: TDSDate, date2: TDSDate) {
        return TDSDate.between(date1, date2, TDSDateUnit.MONTH);
    }

    static between(date1: TDSDate, date2: TDSDate, unit: TDSDateUnit): number {
        switch (unit) {
            case TDSDateUnit.HOUR:
                return Math.floor(Math.abs(date2.getTime() - date1.getTime()) / TDSDate.hourLength);
            case TDSDateUnit.DAY:
                return Math.floor(Math.abs(date2.getTime() - date1.getTime()) / TDSDate.dayLength);
            case TDSDateUnit.MONTH:
                return Math.floor(Math.abs(date2.getTime() - date1.getTime()) / TDSDate.monthLength);
            default:
                throw new Error("Unknown TDSDateUnit '" + unit + "'");
        }
    }

    private static addLeadingZero(num: number): string {
        if (num < 10 && num >= 0) return "0" + num;
        if (num < 0 && num > -10) return "-0" + Math.abs(num);
        return "" + num;
    }
}
