





























import {VueClassTemplate} from "@/util/VueClassTemplate";
import DefaultCard from "@/components/partials/DefaultCard.vue";
import {Component, Watch} from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TDSError from "@/components/common/TDSError.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import InputField from "@/components/common/InputField.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import {ReferralInfo} from "@/interfaces/entities/ReferralInfo";
import TDSCheckbox from "@/components/common/TDSCheckbox.vue";

@Component({
    components: {
        DefaultLayout, TDSError, TDSSpinner, InputField, DefaultCard, TDSButton, TDSCheckbox
    }
})

export default class Referral extends VueClassTemplate {
    private userId: string = this.$route.params.userId;
    private referralInfo: ReferralInfo | null = null;
    private customInput: string = "";
    private inputError: string = "";

    @Watch("error")
    errorChanged() {
        console.log("error changed: ", this.error);
    }

    async init() {
        this.startProgress("loading");
        await this.loadReferralInfo();
        this.endProgress("loading");
    }

    async loadReferralInfo() {
        try {
            this.referralInfo = await this.$store.dispatch("FETCH_REFERRAL_INFO", this.userId);
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
    }

    async manualProcess() {
        try {
            await this.$store.dispatch("MANUAL_REFERRAL_PROCESS", this.userId);
            this.$toastr.success("Successfully processed ", undefined, false);
        } catch (e) {
            this.error = ("Error while sending request: " + e.message);
        } finally {
            await this.loadReferralInfo();
        }
    }

}
