

































































import {Component, Watch} from "vue-property-decorator";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import TDSError from "@/components/common/TDSError.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import InputField from "@/components/common/InputField.vue";
import DefaultCard from "@/components/partials/DefaultCard.vue";
import {VueClassTemplate} from "@/util/VueClassTemplate";
import RestrictUserForm from "@/components/partials/Restriction/RestrictUserForm.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import FlagUserForm from "@/components/partials/UserFlags/FlagUserForm.vue";
import {TransactionHistoryResponse} from "@/interfaces/httpResponses/TransactionHistoryResponse";
import {Style} from "@/interfaces/entities/Style";
import bigDecimal = require("js-big-decimal");
import {Column} from "@/interfaces/entities/Column";
import {TransactionHistoryItemGroup} from "@/interfaces/entities/TransactionHistoryItemGroup";
import moment, {now} from "moment"; //TODO: Adapt this functionality with tds-date
import TDSDatePicker from "@/components/common/TDSDatePicker.vue";
import OutsideClick from "@/components/common/outsideClick.vue";
import LabeledSelect from "@/components/common/LabeledSelect.vue";

/*
- history.package.purchase          #Package purchase
- market-offer.history.purchase     #Data package purchase
- history.store.purchase            #DENT Store Purchase
- history.reward.daily              #Daily Rewards
- history.bonus.reward              #Offer Rewards
- history.bonus.referral            #Referral Bonus
- history.bonus.refund              #Refund
- history.order.sell                #Data package sell
- history.bonus.esim.joiner         #eSIM Joiner Bonus
- history.bonus.joiner              #Joiner Bonus
- history.transfer.withdrawal       #Withdrawal
- history.account.migration         #Account Migration
*/

@Component({
    components: {
        LabeledSelect,
        OutsideClick,
        TDSDatePicker,
    DefaultLayout, TDSError, TDSSpinner, InputField, DefaultCard, RestrictUserForm, TDSButton, FlagUserForm}
})
export default class TransHistory extends VueClassTemplate{
    private userId: string = this.$route.params.userId;
    private transactions: Array<TransactionHistoryItemGroup> = [];
    private groupedTransactions: {[key: string]: Array<TransactionHistoryItemGroup>} = {};
    private isLoadingData: boolean = false;
    private refresh: boolean = false;
    private currentPage: number = 0;
    private totalPages: number = 0;
    private startDate: string = "";
    private endDate: string = "";
    private sortDir: string ="desc";
    private transactionFilters: object = [];
    private showDatepicker: boolean = false;
    private selectedKey: Array<string> | string = "default";
    private allLoaded: boolean = false;

    @Watch("error")
    errorChanged() {
        console.log("error changed: ", this.error);
    }

    async init(){
        this.startProgress("loading");
        await this.loadHistory(true);
        await this.loadKeys();
        window.addEventListener("scroll", this.handleScroll);
        this.endProgress("loading");
        this.allLoaded = true;
    }
    checkKey(){
        console.log(this.selectedKey);
    }
    get getDate(){// @ts-ignore
        return ((this.startDate? (this.$options.filters.dateFormat(this.startDate, "YYYY-MM-DD")): "") + (this.endDate ? (" - " + this.$options.filters.dateFormat(this.endDate, "YYYY-MM-DD")) : "")) ;
    }

    checkDates(date: any){
        let start = moment(date.start);
        start = start.set({
            h: 0,
            m: 0,
            s: 0
        });
        this.startDate = moment(start).format("YYYY-MM-DDTHH:mm:ssZ");
        if(date.end){
            let end = moment(date.end);
            end = end.set({
                h: 23,
                m: 59,
                s: 59});
            this.endDate = moment(end).format("YYYY-MM-DDTHH:mm:ssZ");
        }
        else {this.endDate = ""}
    }

    async loadKeys(){
        const keys = await this.$store.dispatch("FETCH_TRANSACTION_HISTORY_TRANSACTION_FILTERS");
        this.transactionFilters = keys.TransactionHistoryFilters[0];
    }

    async loadHistory(refresh: boolean){
        this.isLoadingData = true;
        let response: TransactionHistoryResponse = {
            paging: {
                pageIndex: 0,
                pageItemLimit: 0,
                totalItemCount: 0,
                totalPageCount: 0,
                maxAllowedPageItemLimit: 0,
                requestTimestamp: "",
                resultTimestamp: "",
                payload: ""
            },
            items: []
        };
        try {
            if (refresh) {
                this.currentPage = 0;
                this.totalPages = 0;
            }

            if (this.endDate) {
                if (this.selectedKey === "default") {
                    response = await this.$store.dispatch("FETCH_TRANSACTION_HISTORY_FROM_TO",
                        {
                            from: this.startDate,
                            to: this.endDate,
                            userId: this.userId,
                            paging: {
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                page_index: this.currentPage,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                page_size: 10,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                sort_dir: this.sortDir,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                sort_by: "timestamp"
                            }
                        }
                    );
                }
                else{
                    response = await this.$store.dispatch("FETCH_TRANSACTION_HISTORY_FROM_TO_SEARCH",
                        {
                            searchKey: this.selectedKey,
                            from: this.startDate,
                            to: this.endDate,
                            userId: this.userId,
                            paging: {
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                page_index: this.currentPage,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                page_size: 10,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                sort_dir: this.sortDir,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                sort_by: "timestamp"
                            }
                        }
                    );
                }
            } else if (this.startDate) {
                response = await this.$store.dispatch("FETCH_TRANSACTION_HISTORY_FROM",
                    {
                        from: this.startDate,
                        userId: this.userId,
                        paging: {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            page_index: this.currentPage,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            page_size: 10,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            sort_dir: this.sortDir,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            sort_by: "timestamp"
                        }
                    }
                );
            }
            else{
                response = await this.$store.dispatch("FETCH_TRANSACTION_HISTORY",
                {
                    userId: this.userId,
                        paging: {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            page_index: this.currentPage,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            page_size: 10,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            sort_dir: this.sortDir,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            sort_by: "timestamp"
                        }
                    }
                );
            } //TODO:Clean up the eslint-stuff
                this.currentPage = response.paging?.pageIndex + 1;
                this.totalPages = response.paging.totalPageCount;
                if (refresh) this.transactions = [];
                this.transactions = this.transactions.concat(response.items);
                this.$set(this, "groupedTransactions", this.groupTransactionsByDay(this.transactions));
            }
        catch
            (e)
            {
                this.error = "<b>Couldn't load the Transaction History: </b><br>" + e.message;
            }
            this.isLoadingData = false;

    }
    handleScroll(){
        if(this.isLoadingData) return;
        const scrollPosition: number = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        const bottomOffset: number = document.documentElement.offsetHeight - (scrollPosition + window.innerHeight);
        if (bottomOffset < 200) {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.loadHistory(false);
            }
        }
    }

    getImageUrl(relativeUrl: string) {
        const imgUrl = new URL(relativeUrl, process.env.VUE_APP_API_URL);
        return imgUrl;
    }
    parseTHStyle(style: Style) {
        if (!style) return "";
        const result = [];
        if (style["font-style"]) {
            if (style["font-style"] === "h1") {
                result.push("font-weight:bold");
            } else if (style["font-style"] === "b1") {
                result.push("color:grey");
            }
        }

        if (style["foreground-color"]) {
            if (style["foreground-color"] === "negative") {
                result.push("color: red");
            } else if (style["foreground-color"] === "neutral") {
                result.push("color: grey");
            } else if (style["foreground-color"] === "positive") {
                result.push("color: limegreen");
            }
        }
        return result.join(";");
    }
    getBalance(row: Column) {
        let decimalValue = new bigDecimal(row.value);
        let precision = 0;
        if (row.currency !== "DENT") precision = Number(row.decimals);
        decimalValue = decimalValue.round(precision, bigDecimal.RoundingModes.HALF_UP);
        let result = (decimalValue.getPrettyValue as Function)();
        result += " " + row.currency;
        if (parseInt(row.value) > 0) {
            if (!(row.formatterOptions && row.formatterOptions.includes("hidePositiveSign"))) {
                result = "+" + result;
            }
        }
        return result;
    }
    groupTransactionsByDay(items: TransactionHistoryItemGroup[]) {
        return items.reduce((acc: {[key: string]: Array<TransactionHistoryItemGroup>}, item: TransactionHistoryItemGroup) => {
            const value: string = moment(item["timestamp"]).format("YYYY-MM-DD");
            acc[value] = Array.isArray(acc[value])? acc[value] : [];
            acc[value].push(item);
            return acc;
        }, {});
    }
}

