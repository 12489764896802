





















































import {Component, Prop} from "vue-property-decorator";
import InputField from "@/components/common/InputField.vue";
import {Feature, FeatureClient} from "@/interfaces/entities/Feature";
import SelectField from "@/components/common/SelectField.vue";
import {KeyValueDictionaryItem} from "@/interfaces/entities/KeyValueDictionaryItem";
import {VueClassTemplate} from "@/util/VueClassTemplate";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSError from "@/components/common/TDSError.vue";

@Component({
    components: {InputField, SelectField, TableList, TDSButton, TDSError}
})
export default class AddRestrictionForm extends VueClassTemplate {
    @Prop() features!: Array<Feature>;
    private name: string = "";
    private selectedFeatures: Array<Feature> = [];
    private selectedFeature: Feature | null = null;

    get featureTableColumns(): TableListColumnConfig {
        return this.isMobile ? {
            name: {label: "Feature Name"},
            remove: {label: "Remove Feature"}
        } : {
            name: {label: "Feature Name"},
            remove: {label: "Remove Feature"}
        };
    }

    //  Prepare features to be shown in HTML select element
    get featureSelectList(): Array<KeyValueDictionaryItem<string>> {
        return [{key: "", value: "Choose Feature..."}].concat(this.features

            // filter out already selected features
            .filter((feature: Feature) => {
                return !this.selectedFeatures.find(({id}: Feature) => id === feature.id);
            })
            .map((feature: Feature) => {
                return {key: feature.id + "", value: this.parseFeatureName(feature)};
            }));
    }

    //  Called by input field on submit
    private inputValidator(val: string): boolean {
        return Boolean(val);
    }

    private onSelectFeature(featureId: string) {
        if (this.isBusy) return;
        const feature = this.features
            .find(({id}: Feature) => id + "" === featureId);
        if (!feature) return;
        this.selectedFeature = feature;
    }

    private addFeature(feature: Feature) {
        this.selectedFeatures.push(feature);
        this.selectedFeature = null;
    }

    private removeFeature(feature: Feature) {
        const index = this.selectedFeatures
            .findIndex(({id}: Feature) => id === feature.id);
        this.selectedFeatures.splice(index, 1);
    }

    //  We have duplicated feature names but the "clients" are different,
    //  so we add the clients too.
    private parseFeatureName(feature: Feature): string {
        const clients: string = feature.featureClients
            .map((client: FeatureClient) => client.operatingSystem)
            .join(", ");
        return `${feature.feature} (${clients})`;
    }

    private async addRestriction() {
        if (this.isBusy || !(this.$refs.restrictionNameInput as InputField).submit() || this.selectedFeatures.length === 0) return;
        this.startProgress("adding_restriction");
        try {
            await this.$store.dispatch("POST_RESTRICTION", {
                name: this.name,
                featureIds: this.selectedFeatures.map(this.getId)
            });
            this.name = "";
            this.selectedFeatures = [];
            (this.$refs.restrictionNameInput as InputField).reset();
            this.$emit("added");
        } catch (e) {
            this.error = "<b>Error while adding restriction occurred:</b><br>" + e.message;
        }
        this.endProgress("adding_restriction");
    }

    getId(feature: Feature){
        return feature.id;
    }
}
