














































































































































































































import {Component, Watch} from "vue-property-decorator";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import TDSError from "@/components/common/TDSError.vue";
import {DentUser} from "@/interfaces/entities/DentUser";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import {KeyValueDictionaryItem} from "@/interfaces/entities/KeyValueDictionaryItem";
import InputField from "@/components/common/InputField.vue";
import DefaultCard from "@/components/partials/DefaultCard.vue";
import {VueClassTemplate} from "@/util/VueClassTemplate";
import RestrictUserForm from "@/components/partials/Restriction/RestrictUserForm.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import FlagUserForm from "@/components/partials/UserFlags/FlagUserForm.vue";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import regexes from "@/util/regexes";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import {GetRestrictionHistory} from "@/interfaces/httpResponses/GetRestrictionHistory";
import moment from "moment";
import {RestrictionHistoryEntry} from "@/interfaces/entities/RestrictionHistoryEntry";
import {KYCInfo} from "@/interfaces/entities/KYCInfo";
import SelectField from "@/components/common/SelectField.vue";

enum editTypes{
    email = "email",
    msisdn = "msisdn"
}

@Component({
    components: {
        SelectField,
        TableList,
        TDSModal,

    DefaultLayout, TDSError, TDSSpinner, InputField, DefaultCard, RestrictUserForm, TDSButton, FlagUserForm}
})
export default class UserSearch extends VueClassTemplate {

    private userId: string = this.$route.params.userId;
    private user: DentUser | null = null;
    private showModal: string = "";
    private editInput: string = "";
    private updateType: editTypes = editTypes.email;
    private twoFactor: string = "";
    private deductError: string = "";
    private editError: string = "";
    private dentToDeduct: number = 0;
    private deductReason: string = "";
    private modalError: string = "";
    private restrictionHistory: Array<RestrictionHistoryEntry>= [];
    private kycInfo: KYCInfo = {level: 0, note: ""};
    private kycUpdate: KYCInfo = {level: 0, note: ""};
    private kycStatus: Array<{name: string;value: number}> = [
        {name: "KYC Level 1", value: 1},
        {name: "KYC Level 2", value: 2},
        {name: "KYC Level 3", value: 3}
    ]
    private hasLoaded: boolean = false;


    @Watch("error")
    errorChanged() {
        console.log("error changed: ", this.error);
    }

    get teaserText(): string {
        return this.user ? "Details about " + this.user.firstName + " " + this.user.lastName + ": " : "";
    }

    get userDetailsAsArray(): Array<KeyValueDictionaryItem<string>> {
        if (!this.user) return [];
        return Object
            .keys(this.user)
            .filter((key: string) => !["restrictions", "whitelistings", "imageUrl"].includes(key))
            .map((key: string) => {
                return {key: key, value: (this.user as DentUser)[key as keyof DentUser] as string};
            });
    }

    showDeductDent(){
        if (!(this.$refs.deductDent as InputField).submit()) return;
        this.showModal = "deduct";
    }
    openMigrateModal(){
        this.showModal = "migrate";
    }

    async migrateUser(){
        this.startProgress("migration");
        try {
            await this.$store.dispatch("MIGRATE_USER_MANUALLY", {
                userId: this.userId,
                searchTerm: this.user?.email,
                twoFactor: this.twoFactor
            });
            this.$toastr.success("Successfully migrated user",undefined,false);
            this.showModal = "";
            this.twoFactor = "";
            await this.loadUser();
        } catch (e) {
            this.error = ("Error while migrating user: " + e.message);
            await this.$toastr.error("Error while migrating the user",undefined,false);
        }
        finally {
            this.endProgress("migration");
        }

    }


    async deductDent(){
        try {
            await this.$store.dispatch("DEDUCT_DENT", {
                userId: this.userId,
                dentToDeduct: this.dentToDeduct,
                reason: this.deductReason
            });
            this.$toastr.success("Successfully deducted " +this.dentToDeduct + " dents",undefined,false);
        } catch (e) {
            this.error = ("Error while deducting dents: " + e.message);
        }
        finally {
            this.showModal = "";
            this.loadUser();
        }

    }

    async init() {
        this.startProgress("loading");
        await this.loadUser();
        await this.loadRestrictionHistory();
        await this.loadKYCInfo();
        this.endProgress("loading");
        this.hasLoaded = true;
    }

    async loadUser() {
        try {
            const response: DentUser = await this.$store.dispatch("FETCH_USER", this.userId);
            this.user = response;
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
    }
    async loadRestrictionHistory(){
        try {
            const response: Array<RestrictionHistoryEntry> = await this.$store.dispatch("GET_RESTRICTION_HISTORY", this.userId);
            this.restrictionHistory = response;
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
    }
    async loadKYCInfo(){
        try {
            const response: KYCInfo = await this.$store.dispatch("GET_KYC_INFO", this.userId);
            this.kycInfo = response;
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
    }
    openKYCModal(){
        this.kycUpdate = JSON.parse(JSON.stringify(this.kycInfo));
        this.showModal = "kyc";
    }
    async updateKYCInfo() {
        console.log("kycUpdate", this.kycUpdate);
        try {
            const response: KYCInfo = await this.$store.dispatch("UPDATE_KYC_INFO", {userId: this.userId, payload: this.kycUpdate});
            console.log("response", response);
            this.closeModal();
            this.loadKYCInfo();
            // this.kycInfo = response;
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
    }

    async removeRestriction() {
        this.startProgress("removing_restriction");
        try {
            await this.$store.dispatch("DELETE_USERS_RESTRICTION", {
                userId: (this.user as DentUser).id,
                restrictionId: (this.user as DentUser).restrictions[0].id
            });
            await this.loadUser();
        } catch (e) {
            this.error = "<b>Couldn't remove restriction from user:</b><br>" + e.message;
        }
        this.endProgress("removing_restriction");
    }
    openEditModal(){this.showModal = "edit"}
    closeModal(){this.showModal = ""}

    async sendChanges(){
        if(!(this.$refs.updateInput as InputField).submit()){
            return;
        }
        this.startProgress("sendChanges");
        try {
            await this.$store.dispatch("EDIT_USERINFORMATION", {userId: this.userId, twoFactor: this.twoFactor, payload:{updateType: this.updateType, value: this.editInput }});
            await this.$toastr.success("Successfully changed the " + this.updateType + " to " + this.editInput, undefined, false);
        }
        catch (e) {
            this.$toastr.error("Error while updating user information.", 5000, true);
        }
        finally {
            this.endProgress("sendChanges");
        }
        await this.loadUser();
        this.showModal = "";

    }

    get resrictionHistoryColumns(): TableListColumnConfig{
        return {
            restriction: {label: "Restriction", sortable: true, width:"110px"},
            note: {label: "Note", sortable: false},
            createdAt: {label: "Created At", sortable: true, width:"130px"},
            deletedAt: {label: "Deleted At", sortable: true, width:"130px"},
            reason: {label: "Reason", sortable: false, width:"110px"}
        };
    }
    formatDate(date: string){
        let parsedDate: number = 0;
        if(!date) return "";
        if(typeof date === "string") {
            if(date.includes("[UTC]")) date = date.replace("[UTC]", "");
            parsedDate = Date.parse(date);
        }
        return (moment(parsedDate)).format("YYYY-MM-DD HH:mm");
    }

    private validateInput(): boolean {
        let isValid = true;
        this.editError = "";
        this.deductError = "";

        // If the input type is email or phone number and we are looking for
        // an exact match, we check if the input value is valid
        // else we can search for any value entered
        if(this.updateType === "msisdn") {
            isValid = regexes.phoneNumberRegex.test(this.editInput);
            if(!isValid) this.editError= this.$t("general.error.invalidPhoneNumber") + "";
        }
        if(this.updateType === "email"){
            isValid = regexes.emailRegex.test(this.editInput);
            if(!isValid) this.editError= this.$t("general.error.invalidEmail") + "";
        }


        if(this.dentToDeduct){
            isValid = regexes.intRegex.test(String(this.dentToDeduct));
            if (!isValid) this.deductError = "The given input is not Valid";
            if (this.dentToDeduct>parseInt(this.user!.dentBalance)){
                isValid = false;
                this.deductError = "The value to deduct is greater than the users current Dent Balance";
            }
        }
        return isValid;
    }
}
