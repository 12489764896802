var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultCard',{attrs:{"title":"Marketplace Listings","is-busy":_vm.isBusy,"error":_vm.error}},[_c('TableList',{attrs:{"columns":_vm.MarketplaceColumns,"rows":_vm.marketplaceOffers,"empty-text":"This user has no marketplace-offers","is-clickable":false},scopedSlots:_vm._u([{key:"column_data",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.display_size + " " + data.display_unit)+" ")]}},{key:"column_validity",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.calcValidity(data.expiresAt) + " Days")+" ")]}},{key:"column_price",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.totalPrice + " DENT")+" ")]}},{key:"column_listedOnMarket",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.calcListed(data.createdAt) === 0? "Today": _vm.calcListed(data.createdAt) + " days ago")+" ")]}},{key:"column_delete",fn:function(ref){
var data = ref.data;
return [_c('a',{staticClass:"delete-link",on:{"click":function($event){$event.stopPropagation();return _vm.openModal(data)}}},[_vm._v("Delete")])]}}])}),(_vm.showModal)?_c('TDSModal',{on:{"close":function($event){_vm.showModal = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Delete Marketplace Listing")]},proxy:true},{key:"body",fn:function(){return [_c('span',[_vm._v("Are you sure you want to delete this marketplace listing?"),_c('br')]),_c('span',[_vm._v(_vm._s(_vm.selectedOffer.display_size + _vm.selectedOffer.display_unit + " - " + _vm.calcValidity(_vm.selectedOffer.expiresAt) + " - " + _vm.selectedOffer.totalPrice + " DENT"))])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"button-wrapper"},[_c('TDSButton',{staticClass:"mr-3",attrs:{"button-style":"primary-red"},on:{"click":_vm.deleteListing}},[_vm._v(" Delete Listing ")]),_c('div',[_c('a',{staticClass:"delete-link",on:{"click":function($event){$event.stopPropagation();_vm.showModal = false}}},[_vm._v("Cancel")])])],1)]},proxy:true}],null,false,843998505)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }