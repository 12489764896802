import _Vue from "vue";
import axiosInstance from "@/util/axios";
import {AxiosInstance} from "axios";

export function Http(Vue: typeof _Vue): void {

    const http: AxiosInstance = axiosInstance;

    Vue.prototype.$http = http;
}
