




import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import InputField from "@/components/common/InputField.vue";

@Component({
    components: {InputField}
})
export default class FAField extends Vue {
    @Prop({type: Boolean, default: false}) isNotEmpty!: boolean;
    private code: string = ""

    @Watch("code")
    codeChanged(value: string) {
        this.$store.commit("SET_TWOFACTORCODE", value);
        this.$emit("update:isNotEmpty", Boolean(value));
    }
}
