import {Dictionary} from "@/interfaces/generic/Dictionary";
import {Component, Vue, Watch} from "vue-property-decorator";

@Component({})
export class VueClassTemplate extends Vue {

    protected error: string = "";
    protected inProgress: Dictionary<number> = {};
    protected isBusy: boolean = false;

    @Watch("inProgress", {deep: true})
    inProgressChanged() {
        this.isBusy = Object.keys(this.inProgress).some((key: string) => this.inProgress[key] > 0);
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        this.init();
    }

    protected async init() {
        return;
    }

    protected startProgress(key: string) {
        if (!this.inProgress[key]) {
            this.$set(this.inProgress, key, 1);
        } else {
            this.$set(this.inProgress, key,  this.inProgress[key] + 1);
        }
    }

    protected endProgress(key: string) {
        if (!this.inProgress[key]) {
            console.error("[VueClassTemplate] Called 'endProgress', but no progress '" + key + "' is running.", this.inProgress);
            return;
        }
        this.$set(this.inProgress, key,  this.inProgress[key] - 1);
    }
}
