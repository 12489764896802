var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"user-search mb-6",on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchUsers($event)}}},[_c('h1',[_vm._v(_vm._s(_vm.$t("userSearch.title")))]),(_vm.error)?_c('TDSError',{attrs:{"message":_vm.error}}):_vm._e(),_c('div',{staticClass:"search-field"},[_c('div',{staticClass:"fields"},[_c('SelectField',{staticClass:"search-key",attrs:{"options":_vm.searchKeys,"name-key":"name","init":_vm.selectedKey,"val-key":"key"},on:{"change":function($event){_vm.selectedKey = $event}}}),_c('InputField',{ref:"searchQueryInput",staticClass:"input-field",attrs:{"placeholder":_vm.$t('userSearch.inputPlaceholder'),"autofocus":true,"validator":_vm.validateInput,"value":_vm.searchQuery},on:{"enter-pressed":_vm.searchUsers,"update:value":function($event){_vm.searchQuery=$event}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.inputError)+" ")]},proxy:true}])}),_c('SelectField',{ref:"searchType",staticClass:"search-type",attrs:{"options":_vm.searchTypes,"init":_vm.findExact,"name-key":"name","val-key":"key"},on:{"change":function($event){_vm.findExact = $event}}})],1),_c('TDSButton',{staticClass:"ml-3 submit-button",attrs:{"button-style":"primary-red","width":"140px","show-spinner":_vm.isSearching,"inactive":!_vm.searchQuery || !_vm.selectedKey || _vm.isSearching},on:{"click":_vm.searchUsers}},[_vm._v(" "+_vm._s(_vm.$t("general.go"))+" ")])],1)],1),(_vm.results)?_c('div',{staticClass:"results fade-in mb-6"},[_c('TableList',{attrs:{"columns":_vm.searchResultColumns,"rows":_vm.results,"is-clickable":true},on:{"row-clicked":_vm.showUserDetailPage},scopedSlots:_vm._u([{key:"column_status",fn:function(ref){
var data = ref.data;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(data.activated ? 'Active' : 'Inactive'),expression:"data.activated ? 'Active' : 'Inactive'"}],class:data.activated ? 'active' : 'inactive'})]}},{key:"column_id",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.id.substring(0, 12) + (data.id.length > 12 ? "..." : ""))+" ")]}},{key:"column_email",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.email)+" ")]}},{key:"column_text",fn:function(ref){
var data = ref.data;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[(data.restrictions.length)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('user is restricted'),expression:"'user is restricted'"}],staticClass:"restricted"}):_vm._e(),(data.iccid && data.iccid.length > 0)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Has data plans'),expression:"'Has data plans'"}],staticClass:"has-esim"}):_vm._e()])]}},{key:"column_firstName",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.firstName)+" ")]}},{key:"column_lastName",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.lastName)+" ")]}}],null,false,719325315)})],1):_vm._e(),_c('UserFlagsList')],1)}
var staticRenderFns = []

export { render, staticRenderFns }