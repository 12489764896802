import {User, UserManager, UserManagerSettings, WebStorageStateStore} from "oidc-client";
import {tools} from "@/util/tools";

export interface Authenticator {
    userManager: UserManager;

    registerUser(): void;

    getRegistrationUrl(): string;

    isAuthenticated(): Promise<boolean>;

    signIn(): Promise<void>;

    signOut(): Promise<void>;

    getUser(): Promise<User | null>;

    getAccessToken(): Promise<string | undefined>;

    getIdToken(): Promise<string | undefined>;
}

const clientBaseUrl = tools.getClientBaseUrl();

const settings: UserManagerSettings = {
    "userStore": new WebStorageStateStore({store: window.localStorage}),
    "authority": process.env.VUE_APP_AUTH_API_URL,
    "client_id": process.env.VUE_APP_AUTH_CLIENT_ID,
    "redirect_uri": clientBaseUrl + "/oidc-callback.html",
    "automaticSilentRenew": true,
    "silent_redirect_uri": clientBaseUrl + "/oidc-silent-renew.html",
    "response_type": "code",
    "scope": "openid profile email",
    "post_logout_redirect_uri": clientBaseUrl + "/",
    "filterProtocolClaims": true
};

export const authenticator: Authenticator = {

    userManager: new UserManager(settings),

    registerUser() {
        window.location.assign(authenticator.getRegistrationUrl());
    },

    getRegistrationUrl(): string {
        const query: string = Object.keys(settings).map((key: string): string => {
            // @ts-ignore
            return `${key}=${settings[key]}`;
        }).join("&");
        return `${process.env.VUE_APP_AUTH_API_URL}/protocol/openid-connect/registrations?${query}`;
    },

    async isAuthenticated(): Promise<boolean> {
        const user: string | undefined = await authenticator.getAccessToken();
        return !!user;
    },

    async signIn(): Promise<void> {
        await authenticator.userManager.signinRedirect();
    },

    async signOut(): Promise<void> {
        await authenticator.userManager.signoutRedirect();
    },

    async getUser(): Promise<User | null> {
        return await authenticator.userManager.getUser();
    },

    async getAccessToken(): Promise<string | undefined> {
        const user: User | null = await authenticator.userManager.getUser();
        if (user?.expired) {
            throw new Error("TOKEN_EXPIRED");
        }
        return user?.access_token;
    },

    async getIdToken(): Promise<string | undefined> {
        const user: User | null = await authenticator.userManager.getUser();
        if (user?.expired) {
            throw new Error("TOKEN_EXPIRED");
        }
        return user?.id_token;
    }
};
