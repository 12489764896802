








import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class TDSError extends Vue {
    @Prop({type: String, default: ""}) title?: string;
    @Prop({type: String, default: ""}) message?: string;
}
