


























import {Component, Prop, Vue} from "vue-property-decorator";
import DefaultNavigationBar from "../partials/DefaultNavigationBar.vue";
import DefaultFooter from "../partials/DefaultFooter.vue";
import DefaultHeader from "@/components/partials/DefaultHeader.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import BubbleTabs from "@/components/partials/Navigation/BubbleTabs.vue";

@Component({
    components: {
        BubbleTabs,
        TDSButton,
        DefaultHeader,
        DefaultFooter,
        DefaultNavigationBar
    }
})
export default class DefaultLayout extends Vue {
    @Prop({type: Boolean, default: true}) showNav!: boolean;
    @Prop({type: Boolean, default: true}) showFooter!: boolean;
    @Prop({type: Boolean, default: false}) showBackButton!: boolean;

    get previousView(): string {
        return this.$store.state.previousView;
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    private goBack() {
        if (this.previousView) {
            this.$router.push(this.previousView);
        } else {
            this.$router.go(-1);
        }
    }
}
