import axios from "@/util/axios";
import {VuexActionContext} from "@/store/index";
import {GetSearchKeysResponse} from "@/interfaces/httpResponses/GetSearchKeysResponse";
import {GetUsersResponse} from "@/interfaces/httpResponses/GetUsersResponse";
import {DentUser} from "@/interfaces/entities/DentUser";
import {RestrictUserRequest} from "@/interfaces/httpRequests/RestrictUserRequest";
import {GetUserFlagsResponse} from "@/interfaces/httpResponses/GetUserFlagsResponse";
import {UserFlag} from "@/interfaces/entities/UserFlag";
import {GetUsersFlagsResponse} from "@/interfaces/httpResponses/GetUsersFlagsResponse";
import {OTP} from "@/interfaces/entities/OTP";
import {CustomOTP} from "@/interfaces/entities/CustomOTP";
import {EditUserinformationRequest} from "@/interfaces/httpRequests/EditUserinformationRequest";
import {GetRestrictionHistory} from "@/interfaces/httpResponses/GetRestrictionHistory";
import {VoicePlanEntry} from "@/interfaces/entities/VoicePlanEntry";
import {CallEntry} from "@/interfaces/entities/CallEntry";
import {ReferralInfo} from "@/interfaces/entities/ReferralInfo";
import {GetKYCInfo} from "@/interfaces/httpResponses/GetKYCInfo";

export const usersState = {};

export const usersMutations = {};

export const usersActions = {

    async FETCH_SEARCH_KEYS(): Promise<GetSearchKeysResponse> {
        return await axios.get("/backoffice/users/search/keys");
    },
    async FETCH_USERS(context: VuexActionContext<void>, query: string): Promise<GetUsersResponse> {
        return await axios.post("/backoffice/users/search?" + query);
    },
    async FETCH_USER(context: VuexActionContext<void>, userId: string): Promise<DentUser> {
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId))).data;
    },

    /* USER RESTRICTION */
    async RESTRICT_USER(context: VuexActionContext<void>, request: RestrictUserRequest): Promise<string> {
        return (await axios.post(`/backoffice/users/${encodeURIComponent(request.userId)}/restrictions`, request));
    },
    async DELETE_USERS_RESTRICTION(context: VuexActionContext<void>, request: RestrictUserRequest): Promise<string> {
        return await axios.delete(`/backoffice/users/${encodeURIComponent(request.userId)}/restrictions/${encodeURIComponent(request.restrictionId)}`); //?restriction_name=${encodeURIComponent(request.restrictionName)}`);
    },
    async GET_RESTRICTION_HISTORY(context: VuexActionContext<void>, userId: string): Promise<GetRestrictionHistory>{
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId) + "/restrictionHistory")).data;
    },

    /* USER FLAGS */
    async CREATE_USER_FLAG(context: VuexActionContext<void>, flagName: string): Promise<string> {
        return await axios.post("/backoffice/flags", {
            flagName
        });
    },
    async FETCH_USER_FLAGS(): Promise<GetUserFlagsResponse> {
        return (await axios.get("/backoffice/flags")).data;
    },
    async FETCH_USERS_FLAGS(context: VuexActionContext<void>, username: string): Promise<GetUsersFlagsResponse> {
        return (await axios.get("/backoffice/users/" + encodeURIComponent(username) + "/flags")).data;
    },
    async FETCH_FLAGGED_USERS(context: VuexActionContext<void>, flagName: string): Promise<UserFlag> {
        return (await axios.get("/backoffice/users/flags/" + flagName)).data;
    },
    async ADD_FLAG_TO_USER(context: VuexActionContext<void>, {
        username, flagName
    }: { username: string; flagName: string }): Promise<void> {
        await axios.post("/backoffice/users/" + encodeURIComponent(username) + "/flags", {
            name: flagName
        });
    },
    async DELETE_FLAG_FROM_USER(context: VuexActionContext<void>, {
        username, flagName
    }: { username: string; flagName: string }): Promise<UserFlag> {
        return (await axios.delete("/backoffice/users/" + encodeURIComponent(username) + "/flags?flag_name=" + encodeURIComponent(flagName)));
    },

    async DELETE_USER(context: VuexActionContext<void>, {
        userId, blacklisted, twoFactor
    }: { userId: string; blacklisted: boolean; twoFactor: string}): Promise<void> {
        await axios.delete("/backoffice/users/" + encodeURIComponent(userId) + "/?blacklisted=" + blacklisted, {
            headers: {
                "two-factor-code": twoFactor
            }
        });
    },

    /* USER PROFILES */
    async FETCH_USER_PROFILES(context: VuexActionContext<void>, userId: string) /*: Promise<DentUser>*/ {
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId) + "/esim-profiles")).data;
    },
    async REPLACE_PROFILE(context: VuexActionContext<void>, payload: any): Promise<DentUser> {
        return (await axios.post("/backoffice/users/" + encodeURIComponent(payload.userId) + "/esim-profiles/replace", {
            iccid: payload.iccid,
            userCountry: payload.userCountry
        })).data;
    },
    async ADD_PROFILE(context: VuexActionContext<void>, userId: string): Promise<DentUser> {
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId) + "/esim-profiles/new")).data;
    },

    /* USER BALANCES */
    async FETCH_USER_PLANS(context: VuexActionContext<void>, userId: string): Promise<DentUser> {
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId) + "/balances?stacked=false")).data;
    },
    async TOPUP_USER(context: VuexActionContext<void>, payload: any): Promise<DentUser> {
        return (await axios.post("/backoffice/users/" + encodeURIComponent(payload.userId) + "/topUpBalance",
            {
                "topupType" : payload.type,
                "countrySet" : "TOPUP",
                "size": payload.size,
                "unit" : payload.unit,
                "isFixed" : true
            })
        ).data;
    },
    async DEDUCT_USER(context: VuexActionContext<void>, payload: any): Promise<DentUser> {
        return (await axios.post("/backoffice/users/" + encodeURIComponent(payload.userId) + "/reduceBalance",
            {
                "size": payload.size,
                "unit" : payload.unit,
                "reason": payload.reason
            })
        ).data;
    },
    async DEDUCT_DENT(context: VuexActionContext<void>, {userId, dentToDeduct, reason}: {userId: string; dentToDeduct: number; reason: string}): Promise<void>{
        await axios.post("/backoffice/users/" + encodeURIComponent(userId) + "/reduceDentBalance", {
            "numberOfDentsToReduce": dentToDeduct,
            "reason": reason
        });
    },
    async DEDUCT_SPECIFIC_PACKAGE(context: VuexActionContext<void>, payload: any): Promise<DentUser>{
        return (await axios.post("/backoffice/users/" + encodeURIComponent(payload.userId) + "/reduceBalance",
            {
                "size": payload.size,
                "unit" : payload.unit,
                "reason": payload.reason,
                "balanceId": payload.balanceId
            })
        ).data;
    },

    /* OTP */
    async SEND_OTP(context: VuexActionContext<void>, userId: string): Promise<void>{
        await axios.post("/backoffice/users/" + encodeURIComponent(userId) + "/otp");
    },
    async SEND_CUSTOM_OTP(context: VuexActionContext<void>, {userId, payload}: {userId: string; payload: CustomOTP}): Promise<void>{
        await axios.post("/backoffice/users/" + encodeURIComponent(userId) + "/otp/custom", {otpType: payload.otpType, receiver: payload.receiver});
    },
    async GET_OTP(context: VuexActionContext<void>, userId: string): Promise<Array<OTP>> {
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId) + "/otp")).data;
    },

    async EDIT_USERINFORMATION(context: VuexActionContext<void>, {userId, payload, twoFactor}: {userId: string; payload: EditUserinformationRequest; twoFactor: string}){
        await axios.put("/backoffice/user/"+ encodeURIComponent(userId) +"/updateMsisdnOrEmail", {updateType: payload.updateType, value: payload.value}, {headers:{"two-factor-code": twoFactor}});
    },

    async GET_LOGINS(context: VuexActionContext<void>, {userId, limit}: {userId: string; limit: number}): Promise<Array<any>>{
        return (await axios.post("/backoffice/users/"+ encodeURIComponent(userId) + "/loginHistory", {
            "page_index" : 0,
            "page_size" : limit,
            "sort_dir" : "desc",
            "sort_by" : "createdAt"
        })).data.LoginHistory;
        // const dummy: Array<string> = ["This is dummy stuff"];
        // return dummy;
    },

    async MIGRATE_USER_MANUALLY(context: VuexActionContext<void>, {userId, searchTerm, twoFactor}: {userId: string; searchTerm: string; twoFactor: string}): Promise<void>{
        await axios.post("/migration/trigger-and-sync", {
            "searchKey": "email",
            "searchTerm": searchTerm,
            "newUserId": userId
        }, {
            headers: {
                "two-factor-code": twoFactor
            }
        });
    },

    /* VOICE PLANS */
    async GET_VOICE_PLANS(context: VuexActionContext<void>, userId: string): Promise<Array<VoicePlanEntry>>{
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId) + "/voice-plans")).data;
    },
    async GET_CALL_HISTORY(context: VuexActionContext<void>, userId: string): Promise<Array<CallEntry>>{
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId) + "/voice-history")).data;
    },

    /* REFERRAL */
    async FETCH_REFERRAL_INFO(context: VuexActionContext<void>, userId: string): Promise<ReferralInfo> {
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId) + "/referral-info")).data;
    },
    async MANUAL_REFERRAL_PROCESS(context: VuexActionContext<void>, userId: string): Promise<void> {
        await axios.post("/backoffice/users/" + encodeURIComponent(userId) + "/manual-referral-process");
    },

    /* KYC */
    async GET_KYC_INFO(context: VuexActionContext<void>, userId: string): Promise<GetKYCInfo> {
        return (await axios.get("/backoffice/users/" + encodeURIComponent(userId) + "/kyc-level")).data;
    },
    async UPDATE_KYC_INFO(context: VuexActionContext<void>, {userId, payload}: {userId: string; payload: {level: number; note: string}}){
        await axios.post("/backoffice/users/"+ encodeURIComponent(userId) +"/kyc-level", {level: payload.level, note: payload.note});
    }
};
