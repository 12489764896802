export const tools = {

    //  return base url of the current gigaStore client
    //  app w/o trailing slash
    getClientBaseUrl(): string {
        let uri: string = window.location.href.split("#")[0];
        uri = uri.split("?")[0];
        while (uri.slice(-1) === "/")
            uri = uri.slice(0, -1);
        return uri;
    },

    convertBytes(bytes: number, destinationUnit: string, originUnit: string = "B", fractionDigits: number = 2) {
        if (!bytes) return 0;
        if (originUnit !== "B") { // convert to bytes first.
            switch (originUnit) {
                case "TB":
                    bytes = Math.floor(bytes * 1024 * 1024 * 1024 * 1024);
                    break;
                case "GB":
                    bytes = Math.floor(bytes * 1024 * 1024 * 1024);
                    break;
                case "MB":
                    bytes = Math.floor(bytes * 1024 * 1024);
                    break;
                case "KB":
                    bytes = Math.floor(bytes * 1024);
                    break;
                default:
                    throw new Error("Unknown byte unit: " + originUnit);
            }
        }
        switch (destinationUnit) {
            case "TB":
                return +(bytes / 1024 / 1024 / 1024 / 1024).toFixed(fractionDigits);
            case "GB":
                return +(bytes / 1024 / 1024 / 1024).toFixed(fractionDigits);
            case "MB":
                return +(bytes / 1024 / 1024).toFixed(fractionDigits);
            case "KB":
                return +(bytes / 1024).toFixed(fractionDigits);
            case "B":
                return bytes;
            default:
                throw new Error("Unknown byte unit: " + destinationUnit);
        }
    },

    saveFile(filename: string, content: Blob | string) {
        let encodedUri;
        if(typeof content === "string") encodedUri = encodeURI(content);
        else if(content instanceof Blob) encodedUri = window.URL.createObjectURL(content);
        else throw new Error("expect content to be Blob or string");
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link); // Required for FF
        link.click();
        setTimeout(() => document.body.removeChild(link));
    }
};
