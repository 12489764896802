


























































import DefaultCard from "@/components/partials/DefaultCard.vue";
import {VueClassTemplate} from "@/util/VueClassTemplate";
import {DentUser} from "@/interfaces/entities/DentUser";
import {Component, Watch} from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TDSError from "@/components/common/TDSError.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import InputField from "@/components/common/InputField.vue";
import RestrictUserForm from "@/components/partials/Restriction/RestrictUserForm.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import FlagUserForm from "@/components/partials/UserFlags/FlagUserForm.vue";
import TDSCheckbox from "@/components/common/TDSCheckbox.vue";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import BubbleTabs from "@/components/partials/Navigation/BubbleTabs.vue";

@Component({
    components: {
        BubbleTabs,
        TDSModal,
        TDSCheckbox,
        DefaultLayout, TDSError, TDSSpinner, InputField, DefaultCard, RestrictUserForm, TDSButton, FlagUserForm}
})
export default class UserDeletion extends VueClassTemplate{
    private userId: string = this.$route.params.userId;
    private user: DentUser | null = null;
    private blacklisted: boolean = true;
    private modalOpen: boolean = false;
    private isChecking: boolean = false;
    private twoFactor: string = "";
    private inputError: string = "";

    @Watch("error")
    errorChanged() {
        console.log("error changed: ", this.error);
    }

    get teaserText(): string {
        return this.user ?  "By pressing the button bellow you will delete this user: " + this.user.firstName + " " + this.user.lastName + ". You will be asked for your 2FA code again. Please keep in mind, that this action cannot be reverted!" : "";
    }

    async init() {
        this.startProgress("loading");
        await this.loadUser();
        this.endProgress("loading");
    }
    async loadUser() {
        try {
            const response: DentUser = await this.$store.dispatch("FETCH_USER", this.userId);
            this.user = response;
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
    }
    deleteUser(){
        this.modalOpen = true;
    }
    async confirmDeletion(){
        this.startProgress("confirmDeletion");
        try {
            await this.$store.dispatch("DELETE_USER",{
                userId: this.userId,
                blacklisted: this.blacklisted,
                twoFactor: this.twoFactor}
            );
            await this.$toastr.success("The user-account of: " +this.user?.firstName +" " +this.user?.lastName + "is deleted.", true, true);
            this.closeModal();
        } catch (e) {
            this.error = e.message;
            await this.$toastr.error("Error while deleting the user: " +e.message);
            this.closeModal();
        }
        this.endProgress("confirmDeletion");
    }
    closeModal(){
        this.modalOpen = false;
    }
}
