





























































import {VueClassTemplate} from "@/util/VueClassTemplate";
import DefaultCard from "@/components/partials/DefaultCard.vue";
import {Component, Watch} from "vue-property-decorator";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TDSError from "@/components/common/TDSError.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import InputField from "@/components/common/InputField.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import {DentUser} from "@/interfaces/entities/DentUser";
import {GetOTPResponse} from "@/interfaces/httpResponses/GetOTPResponse";
import {CustomOTP} from "@/interfaces/entities/CustomOTP";
import moment from "moment";
import regexes from "@/util/regexes";
enum otpTypes {
    msisdn = "msisdn",
    defaultEmail = "defaultEmail",
    email = "email",
}

@Component({
    components: {
        TDSModal,
        TableList,
        DefaultLayout, TDSError, TDSSpinner, InputField, DefaultCard, TDSButton}
})

export default class OTP extends VueClassTemplate{
    private userId: string = this.$route.params.userId;
    private showModal: boolean = false;
    private user: DentUser | null = null;
    private otps: Array<OTP> = [];
    private isSendingOTP: boolean = false;
    private otpType: otpTypes = otpTypes.defaultEmail;
    private customInput: string = "";
    private inputError: string = "";

    @Watch("error")
    errorChanged() {
        console.log("error changed: ", this.error);
    }
    async init(){
        this.startProgress("loading");
        await this.loadUser();
        await this.loadOTP();
        this.endProgress("loading");
    }

    async loadUser() {
        try {
            const response: DentUser = await this.$store.dispatch("FETCH_USER", this.userId);
            this.user = response;
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
    }

    async loadOTP(){
        try {
            this.otps = await this.$store.dispatch("GET_OTP", this.userId);
        }
        catch (e){
            this.error = "<b>Couldnt load OTP-History: </b><br>" +e.message;
        }
    }

    checkOTP(){
        if(this.otpType !== "defaultEmail"){
            if (!(this.$refs.customOTPField as InputField).submit()){
                return;
            }
        }
        else{
            this.customInput = this.user?.email || "";
        }
        this.openModal();

    }

    async sendOTP(){
        this.startProgress("sendingOTP");
        if (this.otpType === "defaultEmail"){
            try {
                await this.$store.dispatch("SEND_OTP", this.userId);
                await this.$toastr.success(`OTP successfully sent to user with the Email: ${this.user?.email}.`, undefined, false);
                this.showModal = false;
            } catch (e) {
                this.$toastr.error("Error while sending OTP to the user. (Email sending failed)", 5000, true);
            }
        }
        else{
            const payload: CustomOTP =
                {otpType: this.otpType, receiver: this.customInput};
            try {

                await this.$store.dispatch("SEND_CUSTOM_OTP", {userId: this.userId, payload});
                await this.$toastr.success(`OTP successfully sent to: ${this.customInput}.`, undefined, false);
                this.showModal = false;
            } catch (e) {
                this.$toastr.error("Error while sending OTP to the user.", 5000, true);
            }
        }

        this.endProgress("sendingOTP");
        await this.loadOTP();
    }

    private validateInput(): boolean {
        let isValid = true;
        this.inputError = "";

        // If the input type is email or phone number and we are looking for
        // an exact match, we check if the input value is valid
        // else we can search for any value entered
        if(this.otpType === "msisdn") {
            isValid = regexes.phoneNumberRegex.test(this.customInput);
            if(!isValid) this.inputError= this.$t("general.error.invalidPhoneNumber") + "";
        }
        if(this.otpType === "email"){
            isValid = regexes.emailRegex.test(this.customInput);
            if(!isValid) this.inputError= this.$t("general.error.invalidEmail") + "";
        }
        return isValid;
    }

    openModal(){
        this.showModal = true;
    }

    closeModal(){
        this.showModal = false;
    }

    formatDate(date: string){
        let parsedDate: number = 0;
        if(!date) return "";
        if(typeof date === "string") {
            if(date.includes("[UTC]")) date = date.replace("[UTC]", "");
            parsedDate = Date.parse(date);
        }
        return (moment(parsedDate)).format("YYYY-MM-DD HH:mm");
    }

    get OTPColumns(): TableListColumnConfig{
        return{
            createdAt: {label: "Timestamp", sortable: true},
            verificationType: {label: "Type", sortable: true},
            otp: {label: "OTP", sortable: false},
            receiver:{label: "Receiver", sortable: true},
            by: {label: "by", sortable: true}
        };
    }
}
