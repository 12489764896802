






























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {KeyValueDictionaryItem} from "@/interfaces/entities/KeyValueDictionaryItem";
import {KeyNameDictionaryItem} from "@/interfaces/entities/KeyNameDictionaryItem";

type OptionItem<ValueType> = KeyNameDictionaryItem<ValueType> | KeyValueDictionaryItem<ValueType>;

@Component
export default class SelectField<ValueType> extends Vue {
    @Prop({type: Boolean, default: false}) autofocus!: boolean;
    @Prop({type: String, default: ""}) placeholder!: string;
    @Prop({type: String}) label!: string;
    @Prop() init!: string | number;
    @Prop({required: true}) options!: Array<OptionItem<ValueType>>;
    @Prop({required: true}) valKey!: keyof OptionItem<ValueType>;
    @Prop({required: true}) nameKey!: keyof OptionItem<ValueType>;
    @Prop({type: Boolean, default: false}) required!: boolean;
    @Prop({type: String, default: "text"}) type!: string;
    @Prop({type: Number, default: 0}) maxLength!: number;
    @Prop({type: Boolean, default: false}) copyField!: boolean;
    @Prop() value!: string | number;

    private id: string = Math.random().toString(36).substr(2, 5);
    private showOptions: boolean = false;

    private selectedOption: OptionItem<ValueType> | null = null;

    get _value(): string | number {
        return this.selectedOption?.[this.nameKey] || "";
    }

    created() {
        if (this.options.length > 0) this.setOption(this.options[0]);
    }

    mounted() {
        if (this.autofocus) {
            this.$nextTick(() => {
                (this.$refs[this.id] as HTMLInputElement)?.focus();
            });
        }
    }

    @Watch("options")
    optionsChanged(to: Array<KeyValueDictionaryItem<ValueType> | KeyNameDictionaryItem<ValueType>>, from: Array<KeyValueDictionaryItem<ValueType> | KeyNameDictionaryItem<ValueType>>) {
        if ((!from || from.length === 0) && to && to.length > 0) {
            if (this.init) {
                this.selectedOption = this.options
                    .find((option: OptionItem<ValueType>) => {
                        return option[this.valKey as keyof OptionItem<ValueType>] === this.init;
                    }) ?? null;
            } else {
                this.setOption(this.options[0]);
            }
        }
    }

    public setOption(option?: OptionItem<ValueType>) {
        this.selectedOption = option ?? null;
        this.$emit("change", this.selectedOption?.[this.valKey]);
    }

    private onFocus() {
        this.showOptions = true;
        this.$emit("focus");
    }

    private onBlur() {
        this.$emit("blur");
        this.$nextTick(() => this.showOptions = false);
    }

    private onKeyDown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            if (this.showOptions) e.preventDefault();
            this.$nextTick(() => this.showOptions = false);
            this.$emit("enter-pressed");
        }

        //  Choose the next or previous item from the
        //  options dropdown on arrow press
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            let index = this.options.findIndex((option: OptionItem<ValueType>) => {
                return option[this.valKey] === this.selectedOption?.[this.valKey];
            });
            index = (index + (e.key === "ArrowUp" ? -1 : 1)) % this.options.length;
            if (index < 0) index += this.options.length;
            this.setOption(this.options[index]);
        }
    }
}
