import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class TDSModal extends Vue {
    @Prop({type: Number, default: 474}) readonly width: number | undefined;
    @Prop({type: Boolean, default: false}) readonly draggable: boolean | undefined;
    @Prop({type: Boolean, default: false}) readonly hideCloseButton: boolean | undefined;

    private componentId: string = "oc" + Math.random().toString(36).substr(2, 6);
    private mouseDownTimestamp: number = 0;
    private dragged: boolean = false;
    private isDragging: boolean = false;
    private mousePositionX: number = 0;
    private mousePositionY: number = 0;
    private offsetX: number = 0;
    private offsetY: number = 0;
    private visible: boolean = false;

    mounted () {
        this.$nextTick(() => {
            document.body.style.position = "fixed";
            document.body.style.width = "100vw";
            setTimeout(() => {
                this.visible = true;
            }, 100);
        });

        //  Necessary to unbind from opening click!
        setTimeout(() => {
            document.addEventListener("mousedown", this.onMouseDown.bind(this));
            document.addEventListener("click", this.onOutsideClick.bind(this));
            document.addEventListener("keyup", this.onEscapeKeyPress.bind(this));
        }, 100);
    }

    beforeDestroy () {
        document.body.style.position = "relative";
        document.body.style.width = "auto";
        this.visible = false;
        document.addEventListener("mousedown", this.onMouseDown.bind(this));
        document.removeEventListener("keyup", this.onEscapeKeyPress.bind(this));
        document.removeEventListener("click", this.onOutsideClick.bind(this));
    }

    public handleScrollerMouseDown (event: MouseEvent): void {
        if (!this.draggable) return;
        this.isDragging = true;
        this.mousePositionX = event.screenX - this.offsetX;
        this.mousePositionY = event.screenY - this.offsetY;
    }

    public handleScrollerMouseMove (event: MouseEvent): void {
        if (this.isDragging) {
            this.dragged = true;
            this.offsetX = event.screenX - this.mousePositionX;
            this.offsetY = event.screenY - this.mousePositionY;
        }
    }

    public onEscapeKeyPress (event: KeyboardEvent): void {
        event = event || window.event;
        let isEscape: boolean = false;
        if ("key" in event) {
            isEscape = event.key === "Escape" || event.key === "Esc";
        }
        if (isEscape) {
            this.isDragging = false;
            this.$emit("close", true);
        }
    }

    public onOutsideClick (event: MouseEvent): void {
        this.isDragging = false;
        // @ts-ignore
        if (Date.now() - this.mouseDownTimestamp < 300 && !event.target.closest("#" + this.componentId)) {
            document.removeEventListener("click", this.onOutsideClick.bind(this));
            this.$emit("close", true);
        }
    }

    public onMouseDown (event: Event): void {
        this.mouseDownTimestamp = Date.now();
    }
}
