































































































































































































import {Component, Watch} from "vue-property-decorator";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import TDSError from "@/components/common/TDSError.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import InputField from "@/components/common/InputField.vue";
import DefaultCard from "@/components/partials/DefaultCard.vue";
import {VueClassTemplate} from "@/util/VueClassTemplate";
import RestrictUserForm from "@/components/partials/Restriction/RestrictUserForm.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import FlagUserForm from "@/components/partials/UserFlags/FlagUserForm.vue";

import {PaymentRecord} from "@/interfaces/entities/PaymentRecord";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import moment from "moment";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import BubbleTabs from "@/components/partials/Navigation/BubbleTabs.vue";
import {SendReceiptRequest} from "@/interfaces/httpRequests/SendReceiptRequest";
import TDSCheckbox from "@/components/common/TDSCheckbox.vue";

@Component({
    components: {
        TDSCheckbox,
        BubbleTabs,
        TDSModal,
        TableList,
        DefaultLayout, TDSError, TDSSpinner, InputField, DefaultCard, RestrictUserForm, TDSButton, FlagUserForm}
})

export default class Payments extends VueClassTemplate{
    private userId: string = this.$route.params.userId;
    private payments: Array<PaymentRecord> = [];
    private searchedPayments: Array<PaymentRecord> = [];
    private showModal: boolean = false;
    private modalView: string = "details";
    private email: string = "";
    private emailRegex: RegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    private searchQuery: string = "";
    private twoFactor: string = "";
    private modalError: string = "";
    private reducePackageBalance: boolean = false;
    private reduceDent: boolean = false;
    private selectedPayment: PaymentRecord = {
        invoice: "",
        method: "",
        amount:"",
        currency:"",
        createdAt:"",
        refundable: false,
        txnId:"",
        reduceDentAllowed: false,
        reducePackBalanceAllowed: false,
        hasReceipt: false,
        paymentId: 0,
        provider: "",
        receiptId: 0,
        state: "",
        requestReducePackBalance: false
    }

    @Watch("error")
    errorChanged() {
        console.log("error changed: ", this.error);
    }
    @Watch("searchQuery")
    queryChanged(){
        this.handleSearch();
    }

    handleSearch(){
        this.searchedPayments = this.payments;
        const regexp = new RegExp(this.searchQuery, "i");
        this.searchedPayments = this.searchedPayments.filter( (x: PaymentRecord) => this.getMatch(Object.values(x), regexp));
    }

    getMatch(array: Array<any>, regex: RegExp){
        let found: boolean = false;
        array.forEach(function(e: any){
            if (regex.test(e)){
                found = true;
            }
        }
        );
        return found;
    }

    async getReceipt(){
        this.startProgress("getReceipt");
        try {
            const txnId = this.selectedPayment.txnId;
            const response: string = await this.$store.dispatch("GET_INVOICE", txnId);
            console.log(response);
            console.log("Get Receipt!");
        }
        catch (e) {
            this.error = "Error while fetching receipt: "+e.message;
            this.$toastr.error("Error while fetching receipt: "+e.message);
        }
        this.endProgress("getReceipt");
    }

    async sendReceipt() {
        this.startProgress("sendReceipt");
        try {
            console.log("[PaymentsPage] Receipt email:", this.email);
            const payload: SendReceiptRequest ={
                txnId: this.selectedPayment.txnId,
                email: this.email
            };
            await this.$store.dispatch("SEND_INVOICE", payload);
            console.log(payload);
        } catch (e) {
            this.error = "Error while sending receipt: " +e.message;
            this.$toastr.error("Error while sending receipt: "+e.message);
        }
        this.endProgress("sendReceipt");
        this.$toastr.success(this.$t("payments.sendReceipt.successful", {email: this.email})).then(() => {
            this.showModal = false;
        });
    }

    showPayment(event: number) {
        // eslint-disable-next-line @typescript-eslint/typedef
        this.selectedPayment= this.payments.find(e => e.id === event)!;
        this.reducePackageBalance= this.selectedPayment.reducePackBalanceAllowed;
        this.reduceDent = this.selectedPayment.reduceDentAllowed;
        this.$set(this, "modalView", "details");
        this.email = "";
        this.showModal = true;
    }

    displayPayment(selected: PaymentRecord) {
        this.selectedPayment= selected;
        this.reducePackageBalance= this.selectedPayment.reducePackBalanceAllowed;
        this.reduceDent = this.selectedPayment.reduceDentAllowed;
        this.$set(this, "modalView", "details");
        this.email = "";
        this.showModal = true;
    }

    get paymentTableColumns(): TableListColumnConfig{
        return {
          invoice: {label: "Invoice", sortable: true},
          provider: {label: "Type/Method", sortable: true},
          amount: {label: "Amount", sortable: true},
          createdAt: {label: "Created At", sortable: true},
          refundable: {label: "Refundable", sortable: true}
        };
    }

    closeModal(){
        this.showModal = false;
    }

    goToRefund(){
        this.modalView = "refund";
    }


    async init(){
        this.startProgress("loading");
        await this.loadPayments();
        this.endProgress("loading");
    }

    async loadPayments(){
        this.startProgress("loadingPayments");
        try {
            const response: Array<PaymentRecord> = await this.$store.dispatch("GET_PAYMENTS",
                {
                    userId: this.userId,
                    paymentBody: {

                    }
                }
            );
            this.$set(this, "payments", response.sort((itemA: PaymentRecord, itemB: PaymentRecord) => {
                if (itemA.createdAt < itemB.createdAt) return 1;
                if (itemA.createdAt > itemB.createdAt) return -1;
                return 0;
            }));
        }  catch (e){
            this.error = "<b>Couldn't load the Payment History: </b><br>" +e.message;
        }
        this.payments.forEach((element: PaymentRecord, index: number) => {element.id = index+1});
        this.payments.forEach((element: PaymentRecord) =>{element.amount = parseFloat(element.amount).toString()});
        this.searchedPayments = this.payments;
        this.endProgress("loadingPayments");
    }

    formatDate(date: string){
        let parsedDate: number = 0;
        if(!date) return "";
        if(typeof date === "string") {
            if(date.includes("[UTC]")) date = date.replace("[UTC]", "");
            parsedDate = Date.parse(date);
        }
        return (moment(parsedDate)).format("YYYY-MM-DD HH:mm");
    }

    async refund() {
        this.startProgress("refunding");
        try {
            await this.$store.dispatch("REFUND_PAYMENT", {
                txnId: this.selectedPayment.txnId,
                reason: "",
                reduceDentAllowed: this.reduceDent,
                reducePackBalanceAllowed: this.reducePackageBalance,
                twoFactorCode: this.twoFactor
            });
            this.$toastr.success(this.$t("payments.refund.successful", {invoice: this.selectedPayment.invoice})).then(() => {
                this.showModal = false;
                this.loadPayments();
            });

        } catch (e) {
            console.log("Error: ", e.response);
            if (e.response?.status === 401) this.modalError = "The 2FA code was not correct.";
            else {
                const details = (e.response.data && e.response.data.message) ? JSON.stringify(e.response.data.message) : JSON.stringify(e.response.data);
                this.modalError = "Error while requesting refund:<br/>" + details;
            }
            this.$toastr.error("Error while trying to refund", 5000, true);
        }
        this.endProgress("refunding");
    }

}
