import Vue from "vue";
import store from "../store";
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from "vue-router";
import {authenticator} from "@/util/authenticator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

Vue.use(VueRouter);


const userTabs = [
    {
        name: "General",
        link: "./general"
    },
    {
        name: "Transactions",
        link: "./transactions"
    },
    {
        name: "eSIM Data",
        link: "./esim-data"
    },
    {
        name: "OTP",
        link: "./otp"
    },
    {
        name: "Payments",
        link: "./payments"
    },
    {
        name: "Login History",
        link: "./login-history"
    },
    {
        name: "Deletion",
        link: "./deletion"
    },
    {
        name: "Marketplace",
        link: "./marketplace"
    },
    {
        name: "Voice Details",
        link: "./voice-details"
    },
    {
        name: "Referral Info",
        link: "./referral-info"
    }
];

const routes: Array<RouteConfig> = [
    {
        path: "/restrictions",
        name: "",
        component: DefaultLayout,
        children: [{
            path: "",
            name: "RestrictionsPage",
            meta: {"userRoles":["admin", "support"]},
            component: () => import(/* webpackChunkName: "restrictions-page" */ "../components/pages/RestrictionsPage.vue")
        }]
    },
    {
        path: "/user/:userId/general",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "UserDetailPage",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-detail-page" */ "../components/pages/UserDetailPage.vue")
        }]
    },
    {
        path: "/user/:userId/transactions",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "UserTransactionHistory",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-transaction-history" */ "../components/pages/UserTransactionHistory.vue")
        }]
    },
    {
        path: "/user/:userId/esim-data",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "UserDataPlans",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-data-plans" */ "../components/pages/UserDataPlans.vue")
        }]
    },
    {
        path: "/user/:userId/payments",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "Payments",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-payments-page" */ "../components/pages/PaymentsPage.vue")
        }]
    },
    {
        path: "/user/:userId/otp",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "OTP",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-otp-page" */ "../components/pages/OTP.vue")
        }]
    },
    {

        path: "/user/:userId/deletion",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "UserDeletion",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-deletion-page" */ "../components/pages/UserDeletion.vue")
        }]
    },
    {

        path: "/user/:userId/login-history",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "LoginHistory",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-login-history-page" */ "../components/pages/LoginHistory.vue")
        }]
    },
    {
        path: "/user/:userId/marketplace",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "Marketplace",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-marketplace-page" */ "../components/pages/Marketplace.vue")
        }]
    },
    {
        path: "/user/:userId/voice-details",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "VoicePlansDetails",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-marketplace-page" */ "../components/pages/VoicePlansDetails.vue")
        }]
    },
    {
        path: "/user/:userId/referral-info",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "Referral Info",
            path: "",
            meta: {
                bubbleTabs: userTabs
            },
            component: () => import(/* webpackChunkName: "user-referral-page" */ "../components/pages/Referral.vue")
        }]
    },
    {

        path: "*",
        name: "",
        component: DefaultLayout,
        children: [{
            name: "UsersPage",
            path: "",
            component: () => import(/* webpackChunkName: "users-page" */ "../components/pages/UsersPage.vue")
        }]
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    authenticator.isAuthenticated().then((authenticated: boolean) => {
        if (authenticated) {
            next();
        } else {
            authenticator.signIn();
        }
    }).catch(() => {
        authenticator.signIn();
    });
    if(to.meta.userRoles && !to.meta.userRoles.includes(store.getters.GET_USER_ROLE)){
        return next("/");
    }
});

router.afterEach(() => {
    store.commit("SET_PREVIOUS_VIEW", "");
    document.body.style.position = "relative";
});

export default router;
