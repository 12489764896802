










































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class InputField extends Vue {
    @Prop({type: Boolean, default: false}) autofocus!: boolean;
    @Prop({type: String, default: ""}) placeholder!: string;
    @Prop({type: String}) label!: string;
    @Prop({required: true}) value!: string | number;
    @Prop({type: Boolean, default: false}) required!: boolean;
    @Prop({type: Function, default: undefined}) validator!: Function;
    @Prop({type: RegExp, default: undefined}) validatorRegExp!: RegExp;
    @Prop({type: String, default: "text"}) type!: string;
    @Prop({type: Number, default: 0}) maxLength!: number;
    @Prop({type: String, default: "none"}) maxWidth!: string;
    @Prop({type: Boolean, default: false}) copyField!: boolean;
    @Prop({type: Boolean, default: false}) readOnly!: boolean;
    @Prop({type: Boolean, default: false}) textField!: boolean;

    private id: string = Math.random().toString(36).substr(2, 5);
    private suppressError: boolean = true;
    private hadFirstFocus: boolean = false;
    private showSuccess: boolean = false;

    get _value(): string | number {
        return this.value;
    }

    get inputValid(): boolean {
        return Boolean(this.value === "" && !this.required) || this._validator(this.value + "");
    }

    get hasError(): boolean {
        return !this.inputValid && !this.suppressError && this.hadFirstFocus;
    }

    mounted() {
        if (this.autofocus) {
            this.$nextTick(() => {
                (this.$refs[this.id] as HTMLInputElement)?.focus();
            });
        }
    }

    private dataChanged(e: Event) {
        const value: string = (e.target as HTMLTextAreaElement)?.value;
        this.$emit("update:value", this.type === "number" ? Number(value) : value);
    }

    //  If this returns false, an error may be shown.
    private _validator(value: string): boolean {
        if (typeof this.validator === "function") {
            return this.validator(value);
        }
        if (this.validatorRegExp === undefined) {
            return Boolean(this.value || !this.required);
        }
        return this.validatorRegExp.test(value);
    }

    private onFocus() {
        this.suppressError = true;
        this.$emit("focus");
    }

    private onBlur() {
        this.suppressError = false;
        this.$emit("blur");
    }

    //  Method to call when form gets submitted. Returns false
    //  if input not valid, and shows error message...
    public submit(): boolean {
        this.suppressError = false;
        this.hadFirstFocus = true;
        return this.inputValid;
    }

    public reset() {
        this.suppressError = true;
        this.hadFirstFocus = false;
    }

    //  On form submit (enter button press) show potential errors.
    //  So set suppressError to false.
    private onKeyDown(e: KeyboardEvent) {
        this.$set(this, "suppressError", !(e.key === "Enter"));
        if (e.key === "Enter") {
            this.$emit("enter-pressed");
        } else {
            this.$set(this, "hadFirstFocus", true);
        }
    }

    private copyToClipboard() {
        if (!this.copyField) return;
        (this.$refs[this.id] as HTMLInputElement).select();
        document.execCommand("copy");
        this.showSuccess = true;
        this.$toastr.success(this.$t("components.inputfield.copied"), true, true);
    }
}
