























import DefaultCard from "@/components/partials/DefaultCard.vue";
import {VueClassTemplate} from "../../util/VueClassTemplate";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import {Component} from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TDSError from "@/components/common/TDSError.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import InputField from "@/components/common/InputField.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import {GetLoginHistoryResponse} from "@/interfaces/httpResponses/GetLoginHistoryResponse";

@Component({
    components: {
        TableList,
        DefaultLayout, TDSError, TDSSpinner, InputField, DefaultCard, TDSButton}
})

export default  class LoginHistory extends VueClassTemplate{
    private userId: string = this.$route.params.userId;
    private logins: GetLoginHistoryResponse = {data: []};
    private limit: number = 100;

    async init(){
        this.startProgress("loading");
        await this.loadLogins();
        this.endProgress("loading");
    }

    async loadLogins(){
        try {
            this.logins = (await this.$store.dispatch("GET_LOGINS", {userId: this.userId, limit: this.limit})).map((e: any) => {
                return {
                    loginDate: this.$options?.filters?.dateFormat(e.createdAt) || e.createdAt,
                    appVersion: e.appVersion,
                    deviceModel: e.deviceModel,
                    deviceOS: e.os + " " + e.osVersion,
                    deviceID: e.deviceId,
                    deviceIP: e.userIp
                };
            });
            // this.logins = [];
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
    }

    get LoginColumns(): TableListColumnConfig{
        return{
            loginDate: {label: "Login Date", sortable: true},
            appVersion: {label: "App Version", sortable: true},
            deviceModel: {label: "Device Model", sortable: true},
            deviceOS: {label: "Device OS", sortable: true},
            deviceIP: {label: "Device IP", sortable:true},
            deviceID:{label: "Device ID", sortable: true}

        };
    }
}
