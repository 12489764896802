










































































import {Component, Vue, Watch} from "vue-property-decorator";
import InputField from "@/components/common/InputField.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import SelectField from "@/components/common/SelectField.vue";
import {SearchKeyItem} from "@/interfaces/entities/SearchKeyItem";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;
import TDSError from "@/components/common/TDSError.vue";
import {GetUsersResponse} from "@/interfaces/httpResponses/GetUsersResponse";
import {DentUser} from "@/interfaces/entities/DentUser";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import regexes from "@/util/regexes";
import {KeyNameDictionaryItem} from "@/interfaces/entities/KeyNameDictionaryItem";
import UserFlagsList from "@/components/partials/UserFlags/UserFlagsList.vue";


@Component({
    components: {SelectField, TDSButton, InputField, TDSError, TableList, UserFlagsList}
})
export default class UsersPage extends Vue {

    private findExact: string = decodeURIComponent((this.$route.query.exact ?? "") as string); // "true" or "false" :D
    private searchQuery: string = decodeURIComponent((this.$route.query.query ?? "") as string);
    private selectedKey: string = decodeURIComponent((this.$route.query.key ?? "") as string);

    private isSearching: boolean = false;
    private searchKeys: Array<SearchKeyItem> = [];
    private searchTypes: Array<KeyNameDictionaryItem<string>> = [
        {
            name: "Find Exact",
            key: "true"
        },
        {
            name: "Find All",
            key: "false"
        }
    ];
    private error: string | TranslateResult = "";
    private inputError: string = "";
    private results: Array<DentUser> | null = null;

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get searchResultColumns(): TableListColumnConfig {
        if (this.isMobile) {
            return {
                id: {label: "#", width: "120px"},
                email: {label: this.$t("general.email") + ""}
            };
        }
        return {
            status: {label: "", width:"35px"},
            id: {label: "#", width: "120px"},
            email: {label: this.$t("general.email") + ""},
            text: {label:"", width: "55px"},
            firstName: {label: this.$t("general.firstName") + ""},
            lastName: {label: this.$t("general.lastName") + ""}
        };
    }

    get searchQueryRequestParams(): string {
        return `page_size=100&page_index=1&search_key=${this.selectedKey}&search_query=${encodeURIComponent(this.searchQuery)}&find_exact=${this.findExact}`;
    }

    @Watch("selectedKey")
    selectedKeyChanged() {
        this.updateUrlQuery({
            key: encodeURIComponent(this.selectedKey) || undefined
        });
    }

    @Watch("findExact")
    findExactChanged() {
        this.updateUrlQuery({
            exact: encodeURIComponent(this.findExact) || undefined
        });
    }

    @Watch("searchQuery")
    searchQueryChanged() {
        this.error = "";
        if (this.selectedKey === "email" || this.selectedKey === "msisdn") {
            this.$nextTick(() => this.searchQuery = this.searchQuery.replace(/\s/g, ""));
        }
        this.updateUrlQuery({
            query: encodeURIComponent(this.searchQuery) || undefined
        });
    }

    created() {
        this.init();
    }

    mounted() {
        if (this.$route.query.exact) {
            (this.$refs.searchType as SelectField<string>)
                .setOption(this.searchTypes
                    .find((type: KeyNameDictionaryItem<string>) => type.key === this.$route.query.exact));
        }
    }

    async init() {
        try {

            //  In case the URL query contains the last search query input
            //  we can grab the last search results for that query from the
            //  local storage. In the 'searchUsers' method, those search results
            //  are stored in the storage
            if(this.searchQuery) {
                const cachedResults = window.localStorage.getItem("last-user-search-results");
                if (cachedResults) {
                    this.results = JSON.parse(cachedResults);
                }
            }

            const {data} = await this.$store.dispatch("FETCH_SEARCH_KEYS");
            this.searchKeys = data;
        } catch (e) {
            this.error = this.$t("userSearch.error.loading", [e.message]);
        }
    }

    private updateUrlQuery(q?: { [key: string]: string | undefined }) {
        const query = {
            ...this.$route.query,
            ...q
        };
        this.$router.replace({query});
    }

    private validateInput(): boolean {
        let isValid = true;
        this.inputError = "";

        // If the input type is email or phone number and we are looking for
        // an exact match, we check if the input value is valid
        // else we can search for any value entered
        if (this.findExact === "true" && this.selectedKey === "email") {
            isValid = regexes.emailRegex.test(this.searchQuery);
            if (!isValid) this.inputError = this.$t("general.error.invalidEmail") + "";
        }
        if (this.findExact === "true" && this.selectedKey === "msisdn") {
            isValid = regexes.phoneNumberRegex.test(this.searchQuery);
            if (!isValid) this.inputError = this.$t("general.error.invalidPhoneNumber") + "";
        }
        return isValid;
    }

    private async searchUsers() {
        try {

            //  This is going to trigger the validation and returns the value of
            //  inputIsValid as a boolean
            if (!(this.$refs.searchQueryInput as InputField).submit()) return;

            this.results = null;
            this.error = "";
            this.isSearching = true;
            const {data} = (await this.$store
                .dispatch("FETCH_USERS", this.searchQueryRequestParams)) as GetUsersResponse;
            this.results = data.users;
            try {
                window.localStorage.setItem("last-user-search-results", JSON.stringify(this.results));
            } catch (e) {
                console.error("[UserSearch] Couldn't cache search results in local storage: ", e);
            }
        } catch (e) {
            this.error = this.$t("userSearch.error.loading", [e.message]);
        } finally {
            this.isSearching = false;
        }
    }

    showUserDetailPage(userId: string) {
        this.$router.push("/user/" + userId + "/general");
    }
}
