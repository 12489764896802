import Vue from "vue";
import VueI18n from "vue-i18n";
import localeEn from "./locale-en.json";

Vue.use(VueI18n);

//  Getting the active language from the clients browser...
let locale = "en"; //window.navigator.language.substring(0, 2).toLowerCase();
if (!["en"].includes(locale)) {
    locale = "en";
}
export const browserLocale = locale;

export default new VueI18n({
    locale,
    messages: {
        en: {
            ...localeEn
        }
    }
});
