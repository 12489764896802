

























































































































































































































import {Component, Vue} from "vue-property-decorator";
import DefaultCard from "@/components/partials/DefaultCard.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import TDSError from "@/components/common/TDSError.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import InputField from "@/components/common/InputField.vue";
import SelectField from "@/components/common/SelectField.vue";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import {DentUser} from "@/interfaces/entities/DentUser";
import TDSCheckbox from "@/components/common/TDSCheckbox.vue";
import QRCode from "qrcode";
import TwoFAField from "@/components/common/TwoFAField.vue";

@Component({
    components: {
        TwoFAField,
        TDSCheckbox,
        DefaultCard,
        TDSSpinner,
        TDSError,
        TDSButton,
        TableList,
        InputField,
        SelectField,
        TDSModal
    }
})
export default class UserDataPlans extends Vue {
    private profilesError: string = "";
    private plansError: string = "";
    private topUpError: string = "";
    private deductError: string = "";
    private modalError: string = "";
    private isLoadingProfile: boolean = false;
    private isLoadingNewProfile: boolean = false;
    private isLoadingPlans: boolean = false;
    private isLoadingTopup: boolean = false;
    private isLoadingDeduct: boolean = false;
    private user: any = {};
    private profiles: any = [];
    private dataPlans: any = [];
    private selectedProfile: any = {};
    private showModal: string = "";
    private has2FA: boolean = false;
    private userCountry: string = "";
    // twoFactorCodeRegex: regexes.twoFACodeRegex,
    private profileToReplace: any = undefined;
    private topupAmount: number = 10;
    private topupUnit: string = "GB";
    // positiveNumberRegex: regexes.positiveNumberRegex,
    private isQaTopup: boolean = true;
    private deductionAmount: number = 1;
    private deductionUnit: string = "GB";
    private deductionReason: string = "";
    private selectedPackage: any;
    private isLoadingSpecificDeduct: boolean = false;

    created() {
        this.getUser();
        this.getProfile().then(this.getPlans);
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get profilesTableColumns(): TableListColumnConfig {
        return {
            // date: {label: "Created at"},
            status: {label: "Status"},
            lastActive: {label: "Last active at"},
            lastCountry: {label: "Last country"},
            iccid: {label: "ICCID"},
            imsi: {label: "IMSI"}
        };
    }
    get dataPlansTableColumns(): TableListColumnConfig {
        return {
            bonus: {label:"", width:"40px"},
            status: {label: this.$t("dataPlans.plans.status") as string},
            country: {label: "Restricted Features"},
            balance: {label: "Balance" },
            expiry: {label: "Expires at"},
            deduct: {label: "Deduct"}
        };
    }
    get deductPlanColumns(): TableListColumnConfig {
        return{
            country: {label: "Restricted Features"},
            balance: {label: "Balance"},
            expiry: {label: "Expires at"}
        };
    }

    get iccidOfEsimProfileToReplace(): string | undefined {
        if (!this.profileToReplace) return undefined;
        return this.profileToReplace.find((item: any): boolean => item.label.toLowerCase() === "iccid").value;
    }

    async getUser() {
        try {
            const response: DentUser = await this.$store.dispatch("FETCH_USER", this.$route.params.userId);
            this.user = response;
        } catch (e) {
            this.profilesError = this.$t("general.error.loading", [e.message]) + "";
        }
    }

    async getProfile() {
        this.isLoadingProfile = true;
        try {
            // const url: string = "/user/" + decodeURI(this.$route.params.msisdn) + "/esim/profiles";
            // const response: GetEsimResponseModel = await this.$http.get(url);
            const response: any = await this.$store.dispatch("FETCH_USER_PROFILES", this.$route.params.userId);
            this.profiles = response.esimProfiles;
            //     .map((profile: Array<UILabelItem>): Array<UILabelItem> => {
            //     return profile.sort((entryA: UILabelItem, entryB: UILabelItem): number => {
            //         if (entryA.sortIndex < entryB.sortIndex) return -1;
            //         if (entryA.sortIndex > entryB.sortIndex) return 1;
            //         return 0;
            //     });
            // }).sort((profileA: Array<UILabelItem>, profileB: Array<UILabelItem>): number => {
            //     return Date.parse(profileB.find((item: UILabelItem): boolean => item.label === "Last Active At")?.value)
            //         - Date.parse(profileA.find((item: UILabelItem): boolean => item.label === "Last Active At")?.value);
            // });
        } catch (e) {
            this.profilesError = `Error while fetching the profile informations: <br><pre>${e.response?.data?.detail ?? e.message}</pre>`;
            console.error("[DataPlans] Error while fetching the profile information.", e);
        } finally {
            this.isLoadingProfile = false;
        }
    }

    async getPlans() {
        this.isLoadingPlans = true;
        try {
            // const response: GetDataPlansResponseModel = await this.$http.get("/user/" + decodeURI(this.$route.params.msisdn) + "/esim/data");
            const response: any = await this.$store.dispatch("FETCH_USER_PLANS", this.$route.params.userId);
            // Sort TopUps
            // response.data.forEach((entry: DataPlansModel) => {
            //     if (entry.topUps) {
            //         entry.topUps.sort((entryA: TopUpModel, entryB: TopUpModel): number => {
            //             if (entryA.createdAt < entryB.createdAt) return -1;
            //             if (entryA.createdAt > entryB.createdAt) return 1;
            //             return 0;
            //         });
            //     }
            // });
            this.$set(this, "dataPlans", response);
        } catch (e) {
            if (e?.response?.status !== 404) {
                this.plansError = `Error while fetching data plans: <br><pre>${e.response?.data?.detail ?? e.message}</pre>`;
            }
            console.error("[DataPlans] Error while fetching the data plans information.", e);
        }
        this.isLoadingPlans = false;
    }

    async replaceProfile(iccid: string) {
        if (this.isLoadingNewProfile) return;
        this.isLoadingNewProfile = true;
        try {
            // const msisdn: string = encodeURI(this.$route.params.msisdn);
            // if (this.profileToReplace) {
            //     const url: string = `/user/${msisdn}/esim/${iccid}/replace?two_factor_auth_code=${this.twoFactorCode}`;
            //     await this.$http.put(url);
            // } else {
            //     const url: string = `/user/${msisdn}/esim/profiles`;
            //     await this.$http.post(url, {
            //         twoFactorCode: this.twoFactorCode
            //     });
            // }
            const response: any = await this.$store.dispatch("REPLACE_PROFILE", {
                userId: this.$route.params.userId,
                iccid: iccid,
                userCountry: this.userCountry
            });
            console.log(response);
            await this.$toastr.success("Profile successfully replaced.");
            this.showModal = "";
            this.profiles = [];
            await this.getProfile();
        } catch (e) {
            this.modalError = `Error while replacing profile.<br><pre>${e.response?.data?.detail || e.message}</pre>`;
        } finally {
            this.isLoadingNewProfile = false;
        }
    }

    async addProfile() {
        if (this.isLoadingNewProfile) return;
        this.isLoadingNewProfile = true;
        try {
            const response: any = await this.$store.dispatch("ADD_PROFILE", this.$route.params.userId);
            console.log(response);
            await this.$toastr.success("New profile successfully issued.");
            this.showModal = "";
            this.profiles = [];
            await this.getProfile();
            this.has2FA = false;
        } catch (e) {
            this.modalError = `Error while issuing new profile.<br><pre>${e.response?.data?.detail || e.message || "Error while adding the new profile."}</pre>`;
        } finally {
            this.isLoadingNewProfile = false;
        }
    }

    async requestBalanceTopup() {
        if (this.isLoadingDeduct) return;
        this.isLoadingTopup = true;
        try {
            await this.$store.dispatch("TOPUP_USER", {
                userId: this.$route.params.userId,
                type: this.isQaTopup ? "QATOPUP" : "MARKETING",
                size: this.topupAmount,
                unit: this.topupUnit
            });
            await this.$toastr.success("Topup successful.");

            await this.getPlans();
        } catch (e) {
            if (e?.response?.status !== 404) {
                this.topUpError = `Error while topup: <br><pre>${e.response?.data?.detail ?? e.message}</pre>`;
            }
            console.error("[DataPlans] Error while topup balance.", e);
        } finally {
            this.isLoadingTopup = false;
        }
    }
    async deductSpecificDeduct() {
        if(this.isLoadingSpecificDeduct) return;
        if(!this.selectedPackage || this.selectedPackage.length === 0) return;
        this.isLoadingSpecificDeduct = true;
        try {
            await this.$store.dispatch("DEDUCT_SPECIFIC_PACKAGE", {
                userId: this.$route.params.userId,
                size: this.deductionAmount,
                unit: this.deductionUnit,
                reason: this.deductionReason,
                balanceId: this.selectedPackage[0].balanceId
            });
            await this.$toastr.success("Deduct successful.");

            await this.getPlans();
        } catch (e) {
            if (e?.response?.status !== 404) {
                this.deductError = `Error while deducting: <br><pre>${e.response?.data?.detail ?? e.message} - ${e.response?.data.message || "Failed to deduct"}</pre>`;
            }
            console.error("[DataPlans] Error while deducting balance.", e);
            await this.$toastr.error("Error while deducting the data package.", 5000, true);
        } finally {
            this.deductionAmount = 0;
            this.deductionUnit = "GB";
            this.deductionReason = "";
            this.isLoadingSpecificDeduct = false;
            this.showModal = "";
}

    }

    async requestBalanceDeduct() {
        if (this.isLoadingDeduct) return;
        this.isLoadingDeduct = true;
        try {
            await this.$store.dispatch("DEDUCT_USER", {
                userId: this.$route.params.userId,
                size: this.deductionAmount,
                unit: this.deductionUnit,
                reason: this.deductionReason
            });
            await this.$toastr.success("Deduct successful.");

            await this.getPlans();
        } catch (e) {
            if (e?.response?.status !== 404) {
                this.deductError = `Error while deducting: <br><pre>${e.response?.data?.detail ?? e.message}</pre>`;
            }
            console.error("[DataPlans] Error while deducting balance.", e);
        } finally {
            this.isLoadingDeduct = false;
        }
    }


    private getQRCode(text: string | null | undefined): string {
        if (!text) return "";
        let QRUrl: string = "";
        QRCode.toDataURL(text, (err: any, url: any): any => {
            QRUrl = url;
        });
        return QRUrl;
    }

    private showProfile(profileId: number) {
        const profile = this.profiles.find((p: any) => p.id === profileId);
        this.selectedProfile = Object.fromEntries(
            Object.entries(profile).sort((a: any, b: any) => {
                // console.log(typeof a[1] + " | " + a[1]);
                a = typeof a[1] !== "object" ? a[1].toString() : "";
                b = typeof b[1] !== "object" ? b[1].toString() : "";
                if (a < b) {
                    return -1;
                }
                if (b > a) {
                    return 1;
                }

                return 0;
            }).filter((entry: any) => entry[1])
        );
        this.showModal = "profile";
    }

    private closeModal(type: string) {
        if(type === "profile") this.selectedProfile = {};
        this.showModal = "";
    }
    private openDeductModal(data: any){
        this.selectedPackage = [data];
        this.showModal = "deductData";
        this.deductError = "";
    }
}

