



































































import {Component} from "vue-property-decorator";
import {VueClassTemplate} from "@/util/VueClassTemplate";
import DefaultCard from "../DefaultCard.vue";
import InputField from "@/components/common/InputField.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import {GetUserFlagsResponse} from "@/interfaces/httpResponses/GetUserFlagsResponse";
import {UserFlag} from "@/interfaces/entities/UserFlag";
import {Dictionary} from "@/interfaces/generic/Dictionary";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import {GetUsersResponse} from "@/interfaces/httpResponses/GetUsersResponse";

@Component({
    components: {DefaultCard, InputField, TDSButton, TableList}
})
export default class UserFlagsList extends VueClassTemplate {

    newFlagName: string = "";
    flags: string[] = [];
    selectedFlags: string[] = [];
    flagsWithUsernames: Dictionary<UserFlag> = {};

    get userTableColumn(): TableListColumnConfig {
        if(!this.isMobile) {
            return {
                flagname: {label: "Flag Name", width: "180px"},
                username: {label: "Username"},
                remove: {label: "Remove", width: "140px"},
                open: {label: "Open", width: "140px"}
            };
        } else {
            return {
                username: {label: "Username"},
                open: {label: "Open", width: "90px"}
            };
        }
    }

    /**
     * @returns {string[]} usernames
     */
    get selectedFlaggedUsers(): { username: string; flagName: string }[] {
        return this.selectedFlags
            .flatMap((flagName: string) => {
                return this.flagsWithUsernames[flagName]?.users
                    .map((user: { username: string }) => {
                        return {flagName, ...user};
                    }) ?? [];
            });
    }

    /**
     * @override
     */
    async init() {
        this.startProgress("loading");

        // TODO: error handling

        const response: GetUserFlagsResponse = await this.$store.dispatch("FETCH_USER_FLAGS");
        this.flags = response.flags;

        //  Preselect some flag on first load
        if (this.flags.length > 0) await this.selectFlag(this.flags[0]);

        this.endProgress("loading");
    }

    async addFlag() {
        this.error = "";
        if (!(this.$refs.searchQueryInput as InputField).submit()) return;
        this.startProgress("add_flag");
        try {
            await this.$store.dispatch("CREATE_USER_FLAG", this.newFlagName);
            this.newFlagName = "";
            (this.$refs.searchQueryInput as InputField).reset();
            await this.init();
        } catch (e) {
            this.error = `<b>An error occurred while creating the flag:</b><br>${e.message}`;
        }
        this.endProgress("add_flag");
    }

    async selectFlag(flag: string) {
        if (this.selectedFlags.includes(flag)) {
            this.selectedFlags = [
                ...this.selectedFlags.filter((f: string) => f !== flag)
            ];
        } else {
            await this.loadFlaggedUsers(flag);
            this.selectedFlags.push(flag);
        }
    }

    async loadFlaggedUsers(flag: string) {
        this.startProgress("loading_" + flag);
        try {
            const userFlag: UserFlag = await this.$store.dispatch("FETCH_FLAGGED_USERS", flag);
            this.flagsWithUsernames[userFlag.flagName] = userFlag;
        } catch (e) {
            this.error = "<b>An error occurred while loading users with flag '" + flag + "':</b><br>" + e.message;
        }
        this.endProgress("loading_" + flag);
    }

    async removeFlagFromUser(userFlag: { username: string; flagName: string }) {
        this.startProgress("remove_flag");
        try {
            await this.$store.dispatch("DELETE_FLAG_FROM_USER", userFlag);
        } catch (e) {
            this.error = "<b>An error occurred while deleting the flag from the user:</b><br>" + e.message;
        }
        this.endProgress("remove_flag");
    }

    async openUser(username: string) {
        this.startProgress("loading_user");
        try {
            const query = `page_size=1&page_index=1&search_key=login&search_query=${encodeURIComponent(username)}&find_exact=true`;
            const response: GetUsersResponse = await this.$store.dispatch("FETCH_USERS", query);
            await this.$router.push("/user/" + response.data.users[0].id + "/general");
        } catch(e) {
            this.error = "<b>Error on loading user information:</b><br>" + e.message;
        }
        this.endProgress("loading_user");
    }
}
