














































import {Component, Prop, Vue} from "vue-property-decorator";

export interface Column {
    label: string;
    sortable?: boolean;
    sortBy?: any;
    align?: "right" | "left" | "center";
    width?: string;
}

export interface TableListColumnConfig {
    [key: string]: Column;
}

type SortDirection = "DESC" | "ASC";

@Component
export default class TableList<RowType> extends Vue {
    @Prop() rows!: Array<RowType>;
    @Prop() groupedKey!: string;
    @Prop() itemKey!: string;
    @Prop() columns!: Column[];
    @Prop({type: Boolean, default: false}) isClickable!: boolean;
    @Prop() rowHeight!: string;
    @Prop({type: String}) emptyText!: string;

    private currentSort: keyof RowType = "" as keyof RowType;
    private currentSortDir: SortDirection = "ASC";

    created() {
        const key = this.$route.query.sortKey;
        if (key) {
            this.currentSort = key as keyof RowType;
        }
        const dir = this.$route.query.sortDir;
        if (dir) {
            this.currentSortDir = dir as SortDirection;
        }
    }

    sort(key: keyof RowType) {
        if (key === this.currentSort) {
            this.currentSortDir = this.currentSortDir === "ASC" ? "DESC" : "ASC";
        }
        this.currentSort = key;
        this.$router.push({query: {sortDir: this.currentSortDir, sortKey: this.currentSort as string}});
    }

    get sortedRows(): Array<RowType> {
        if (this.currentSort) {
            let sorting: any = this.currentSort;
            if (this.columns[sorting]?.sortBy) sorting = this.columns[sorting].sortBy;
            return this.rows.sort((a: RowType, b: RowType) => {
                if(typeof sorting === "function") return this.getSortIndex(sorting(a), sorting(b));
                //@ts-ignore
                else return this.getSortIndex(a[this.currentSort], b[this.currentSort]);
            });
        } else return this.rows;
    }

    private getSortIndex(a: string | number, b: string | number) {
        const sortIsAscending = this.currentSortDir === "ASC";
        if(typeof a === "string" && typeof b === "string") {
            a = a.toLowerCase();
            b = b.toLowerCase();
        }
        if (a < b) {
            return sortIsAscending ? -1 : 1;
        }
        if (a > b) {
            return sortIsAscending ? 1 : -1;
        }
        return 0;
    }

    get isMobile() {
        return this.$store.state.isMobile;
    }

    private flexAlign(align: string): string {
        switch (align) {
            case "left":
                return "flex-start";
            case "center":
                return "center";
            case "right":
                return "flex-end";
            default:
                return align;
        }
    }
}

