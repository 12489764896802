









































import {Component} from "vue-property-decorator";
import {Feature} from "@/interfaces/entities/Feature";
import {Restriction} from "@/interfaces/entities/Restriction";
import {VueClassTemplate} from "@/util/VueClassTemplate";
import AddRestrictionForm from "@/components/partials/Restriction/AddRestrictionForm.vue";
import DefaultCard from "@/components/partials/DefaultCard.vue";
import TableList, {TableListColumnConfig} from "@/components/common/TableList.vue";
import TDSButton from "@/components/common/TDSButton.vue";

@Component({
    components: {AddRestrictionForm, DefaultCard, TableList, TDSButton}
})
export default class RestrictionsPage extends VueClassTemplate {

    private features: Array<Feature> = [];
    private restrictions: Array<Restriction> = [];

    get restrictionTableColumns(): TableListColumnConfig {
        return this.isMobile ? {
            name: {label: "Restriction Name"},
            remove: {label: "Remove"}
        } : {
            name: {label: "Restriction Name"},
            features: {label: "Restricted Features"},
            remove: {label: "Remove"}
        };
    }

    async init() {
        this.startProgress("loading");
        try {
            const [features, restrictions] = await Promise.all([
                this.$store.dispatch("FETCH_FEATURES"),
                this.$store.dispatch("FETCH_RESTRICTIONS")
            ]);
            this.features = features;
            this.restrictions = restrictions;
        } catch (e) {
            this.error = this.$t("general.error.loading", [e.message]) + "";
        }
        this.endProgress("loading");
    }

    async removeRestriction(restriction: Restriction) {
        if (this.isBusy) return;
        this.startProgress("removing_restriction");
        try {
            await this.$store.dispatch("DELETE_RESTRICTION", restriction.id);
            await this.init();
        } catch (e) {
            this.error = "<b>Error while deleting restriction occurred:</b><br>" + e.message;
        }
        this.endProgress("removing_restriction");
    }
}
