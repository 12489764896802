import axios from "@/util/axios";
import {TransactionHistoryResponse} from "@/interfaces/httpResponses/TransactionHistoryResponse";
import {VuexActionContext} from "@/store/index";
import {TransactionHistoryRequest} from "@/interfaces/httpRequests/TransactionHistoryRequest";
import {PaymentsRequest} from "@/interfaces/httpRequests/PaymentsRequest";
import {GetPaymentsResponse} from "@/interfaces/httpResponses/GetPaymentsResponse";
import {SendReceiptRequest} from "@/interfaces/httpRequests/SendReceiptRequest";
import {PaymentRecord} from "@/interfaces/entities/PaymentRecord";
import {tools} from "@/util/tools";
import {RefundRequest} from "@/interfaces/httpRequests/RefundRequest";
import {MarketplaceOffer} from "@/interfaces/entities/MarketplaceOffer";
import {GetTransactionFilters} from "@/interfaces/httpResponses/GetTransactionFilters";

export interface TransactionState{
    payments: Array<PaymentRecord>;
}

export const transactionHistoryActions = {
    async FETCH_TRANSACTION_HISTORY(context: VuexActionContext<void>, payload: TransactionHistoryRequest): Promise<TransactionHistoryResponse>{
        return (await axios.post("/backoffice/users/" + encodeURIComponent(payload.userId) + "/transaction-history", payload.paging)).data;
    },
    async FETCH_TRANSACTION_HISTORY_FROM(context: VuexActionContext<void>, payload: TransactionHistoryRequest): Promise<TransactionHistoryResponse>{
        return (await axios.post("/backoffice/users/" + encodeURIComponent(payload.userId) + "/transaction-history/search/date", {from: payload.from, paging: payload.paging, key: "lt"})).data;
    },
    async FETCH_TRANSACTION_HISTORY_FROM_TO(context: VuexActionContext<void>, payload: TransactionHistoryRequest): Promise<TransactionHistoryResponse>{
        return (await axios.post("/backoffice/users/" + encodeURIComponent(payload.userId) + "/transaction-history/search/date", {from: payload.from, to: payload.to, paging: payload.paging, key:"bw"})).data;
    },
    async FETCH_TRANSACTION_HISTORY_FROM_TO_SEARCH(context: VuexActionContext<void>, payload: TransactionHistoryRequest): Promise<TransactionHistoryResponse>{
        return (await axios.post("/backoffice/users/" + encodeURIComponent(payload.userId) + "/transaction-history/search/date", {from: payload.from, to: payload.to, paging: payload.paging, key:"ts", searchKey: payload.searchKey})).data;
    },
    async FETCH_TRANSACTION_HISTORY_TRANSACTION_FILTERS(context: VuexActionContext<void>): Promise<GetTransactionFilters> {
        return (await axios.get("/backoffice/transactionFilters")).data;
    },

    async GET_PAYMENTS(context: VuexActionContext<void>, payload: PaymentsRequest): Promise<GetPaymentsResponse>{
        return (await axios.post("/backoffice/users/" + encodeURIComponent(payload.userId) + "/payment", payload.paymentBody)).data;
    },

    async SEND_INVOICE(context: VuexActionContext<void>, payload: SendReceiptRequest): Promise<void>{
        await axios.post("/backoffice/payments/receipt", payload);
    },
    async GET_INVOICE(context: VuexActionContext<void>, tnxId: string) {
        axios.get("/backoffice/payment/invoice/" + encodeURIComponent(tnxId), {responseType: "blob"}).then((response: {data: string}) => {
            tools.saveFile(
                `dent-payments-invoice-${Math.floor(Date.now() / 1000)}.pdf`,
                response.data
            );
        });
    },
    async REFUND_PAYMENT(context: VuexActionContext<void>, payload: RefundRequest): Promise<void>{
        await axios.post("/backoffice/payments/refund", payload);
    },
    async GET_MARKET_OFFERINGS(context: VuexActionContext<void>, userId: string): Promise<Array<MarketplaceOffer>>{
        return (await axios.get("/backoffice/users/"+ encodeURIComponent(userId) + "/marketOfferListings")).data;

        /*return [{
            "displaySize": 0.1,
            "displayUnit": "GB",
            "totalPrice": 143.000000000000000000,
            "priceRounding": "halfUp",
            "totalPriceRounding": "halfUp",
            "createdAt": "2022-02-09T08:24:38Z",
            "expiresAt": "2022-04-04T08:21:11Z"
        }];*/
    },
    async DELETE_MARKET_OFFER(context: VuexActionContext<void>, {userId, offerId}: {userId: string; offerId: number}): Promise<Array<MarketplaceOffer>>{
        return (await axios.delete("/backoffice/users/"+ encodeURIComponent(userId) + "/marketOfferListings",  {params: {marketOfferId: offerId}})).data;
    }
};
