import Vue from "vue";
import Vuex from "vuex";
import axios from "../util/axios";
import {VersionInfo, VersionResponse} from "@/interfaces/httpResponses/GetVersionResponse";
import {usersActions, usersMutations, usersState} from "./usersStore";
import {featureRestrictionActions} from "@/store/featureRestrictionStore";
import {transactionHistoryActions} from "@/store/transactionHistoryStore";

Vue.use(Vuex);

export interface DefaultState {
    auth: string;
    twoFactorCode: string;
    apiVersion: string;
    headerHeight: string;
    isMobile: boolean;
    previousView: string;
}

export interface VuexActionContext<T> {
    commit: (mutationId: string, data: T) => void;
}

export default new Vuex.Store({
    state: {
        auth: "",
        twoFactorCode: "",
        apiVersion: "",
        headerHeight: "213px",
        isMobile: false,
        previousView: "",
        ...usersState
    },
    mutations: {
        SET_AUTH(state: DefaultState, auth: string) {
            state.auth = auth;
        },
        SET_TWOFACTORCODE(state: DefaultState, twoFactorCode: string) {
            state.twoFactorCode = twoFactorCode;
        },
        SET_API_VERSION(state: DefaultState, versionInfo: VersionInfo) {
            state.apiVersion = `${versionInfo.version} (${versionInfo.commitHash})`;
        },
        SET_HEADER_HEIGHT(state: DefaultState, headerHeight: string) {
            state.headerHeight = headerHeight;
        },
        SET_IS_MOBILE(state: DefaultState, isMobile: boolean) {
            state.isMobile = isMobile;
        },
        SET_PREVIOUS_VIEW(state: DefaultState, previousView: string) {
            state.previousView = previousView;
        },
        ...usersMutations
    },
    actions: {
        async FETCH_API_VERSION({commit}: VuexActionContext<VersionInfo>) {
            const response: VersionResponse = await axios.get("/version");
            commit("SET_API_VERSION", response.data);
        },
        ...usersActions,
        ...featureRestrictionActions,
        ...transactionHistoryActions
    },
    getters: {
        GET_USER_ROLE(state: DefaultState){
            if(!state.auth) return "public";
            const userRoles: Array<string> = JSON.parse(atob(state.auth.split(".")[1]))["roles"];
            if (userRoles.includes("ROLE_ADMIN")){
                return "admin";
            }
            else if(userRoles.includes("ROLE_SUPPORT")){
                return "support";
            }
            else{
                return "limitedSupport";
            }
        }
    }
});
