import axios from "@/util/axios";
import {Feature} from "@/interfaces/entities/Feature.ts";
import {Restriction} from "@/interfaces/entities/Restriction";
import {VuexActionContext} from "@/store/index";
import {LegalContent} from "@/interfaces/entities/LegalContent";

export const featureRestrictionActions = {
    async FETCH_FEATURES(): Promise<Array<Feature>> {
        return (await axios.get("/features")).data;
    },
    async FETCH_RESTRICTIONS(): Promise<Array<Restriction>> {
        return (await axios.get("/backoffice/restrictions")).data?.restrictions;
    },
    async POST_RESTRICTION(context: VuexActionContext<LegalContent>, restriction: Restriction): Promise<Array<Restriction>> {
        return (await axios.post("/backoffice/restrictions", restriction)).data;
    },
    async DELETE_RESTRICTION(context: VuexActionContext<LegalContent>, restrictionId: number): Promise<Array<Restriction>> {
        return (await axios.delete("/backoffice/restrictions/" + restrictionId)).data;
    }
};
