










import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class LabeledSelect extends Vue {
    @Prop({type: String}) label!: string;
    private componentId: string = "oc" + Math.random().toString(36).substr(2, 6);

}
