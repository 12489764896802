




















import {Component, Prop} from "vue-property-decorator";
import InputField from "@/components/common/InputField.vue";
import SelectField from "@/components/common/SelectField.vue";
import {VueClassTemplate} from "@/util/VueClassTemplate";
import TableList from "@/components/common/TableList.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSError from "@/components/common/TDSError.vue";
import {KeyValueDictionaryItem} from "@/interfaces/entities/KeyValueDictionaryItem";
import {Restriction} from "@/interfaces/entities/Restriction";
import {DentUser} from "@/interfaces/entities/DentUser";

@Component({
    components: {InputField, SelectField, TableList, TDSButton, TDSError}
})
export default class RestrictUserForm extends VueClassTemplate {

    private selectedRestriction: Restriction | null = null;
    private restrictions: Array<Restriction> = [];
    @Prop() user!: DentUser;
    @Prop() error!: string;

    get restrictionSelectList(): Array<KeyValueDictionaryItem<string>> {
        return this.restrictions.map((restriction: Restriction) => {
            return {
                key: restriction.id + "",
                value: restriction.name
            };
        });
    }

    async init() {
        this.startProgress("loading");
        try {
            this.restrictions = await this.$store.dispatch("FETCH_RESTRICTIONS");
        } catch (e) {
            console.error("[RestrictUserForm] Error: ", e);
            this.$emit("update:error", this.$t("general.error.loading", [e.message]) + "");
        }
        this.endProgress("loading");
    }

    private async restrictUser() {
        if (this.isBusy || !this.selectedRestriction) return;
        this.startProgress("adding_restriction");
        try {
            await this.$store.dispatch("RESTRICT_USER", {
                note: "Restricted via backoffice user.",
                name: this.selectedRestriction.name,
                userId: this.user.id
            });

            this.$emit("added");
        } catch (e) {
            console.error("[RestrictUserForm] Error: ", e);
            this.$emit("update:error", "<b>Error while restricting user occurred:</b><br>" + e.message);
        }
        this.endProgress("adding_restriction");
    }

    private onSelectRestriction(restrictionId: string) {
        this.selectedRestriction = this.restrictions.find(({id}: Restriction) => id + "" === restrictionId) ?? null;
    }
}
